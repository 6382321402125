import React,{ useState, useEffect }  from "react";
import { OurBookMenu } from "../Utils/OurBookMenu";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import { Outlet } from "react-router-dom";
import * as Comman from "../Utils/comman";
function OurbookNavbar() {
  const [bookList, setBookList] = useState([]);
  useEffect(() => {
    getBooks();
  }, []);
  const getBooks = (ageRange='',pageNumber = '', pageSize ='5') => {
    const data = {
        page: pageNumber,
        per_page: pageSize,
        age_range: ageRange
    }
    Comman.callAPI("post", "/all-books", data).then((res) => {
        if (typeof res !== "undefined") {
            setBookList(res.result);
        } 
    });
    
  }
  return (
    <>
      <div className="col-md col-6">
        <div className="footer-widget">
          <h5 className="footer-widget-title text-2xl">Sample Books</h5>
          <ul className="footer-widget-links list-inline">
            {/* {OurBookMenu.map((item, index) => {
              if (!("comp" in item)) {
                return (
                  <li key={index} className="nav-item">
                    <a href={item.externalurl} className={item.cName}>
                      {item.title}
                    </a>
                  </li>
                );
              } else {
                return (
                  <li key={index} className="nav-item">
                    <NavLink key={index} to={item.path} className={item.cName}>
                      <span>{item.title}</span>
                    </NavLink>
                  </li>
                );
              }
            })} */}
            {/* {console.log(bookList)} */}
            {bookList.map( book => (
              <li key={book} className="nav-item">
                  <a href={'/book-detail/'+book.slug} className='nav-link'>
                    {book.book_name}
                  </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
}
export default OurbookNavbar;
