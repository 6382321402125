import React, {useState, useEffect } from "react";
// import { SocialMenu } from '../Utils/SocialMenu'
import * as Comman from "../Utils/comman";
function SocialNavbar(props) {
    const [socialMenu, setSocialMenu] = useState([]);
    const [isSocailGet, setisSocailGet] = useState(false);
    useEffect(() => {
      if(!isSocailGet){
        getSocialMenu();
      }

    }, []);
    const getSocialMenu = () => {
      Comman.callAPI("get", "/social-media-links").then((res) => {
          if (typeof res !== "undefined") {
            setisSocailGet(true);
            setSocialMenu(res);
          }
      });
    }
    return (
        <div class="social-networks">
            {socialMenu.map((item, index) => {
              return (
                <p>
                <a className={item.title} href={item.path} target="_blank">
                  <i className={item.icon}></i>
                  
                </a>
                {item.title}
                </p>
              );
            })}
        </div>
    );
}
export default SocialNavbar;