import React, { useState, useEffect } from "react";
import * as Comman from "../Utils/comman";
import { Helmet } from "react-helmet";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import { useParams } from "react-router-dom";
import kids from '../images/assets/kids.svg';
import pages from '../images/assets/pages.svg';
import open_book from '../images/assets/open-book.svg'
import OwlCarousel from "react-owl-carousel";
import image1 from '../images/assets/book-cover.png';
import { Redirect } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import queryString from "query-string";
import { useGlobalState } from '../Context/currency-context';


function BookDetail(props) {
    const navigate = useNavigate();
    console.log("Params:", useParams());

    let { bookid } = useParams();
    const [bookData, setBookData] = useState([]);
    const [book_images, setBookImage] = useState([]);
    const [isLoad, setIsLoad] = useState(false);
    const [form_fields, setFormFields] = useState([]);

    const [values, setValues] = useState({
        'your_name': '',
        // 'friend_name': 'Arya',
        'your_gender': '',
        // 'friend_gender': 'Boy',
    });
    const [gender, SetGender] = useState();
    const [price_type_list, setPriceTypeList] = useState({
        price_type: []
    });
    const [price_type_dict, setPriceTypeDict] = useState({});
    const [LowestPrice, setLowestPrice] = useState();
    const [LowestPriceType, setLowestPriceType] = useState();
    const [avlin, setAvlIn] = useState();
    const [user_id, setUserId] = useState();
    const [is_login, setIsLogin] = useState('no');
    const [book_values, setBookValues] = useState([]);
    const [style, setStyle] = useState("book_description");
    const [style1, setStyle1] = useState("");
    const [read_content, setReadContent] = useState("Read More");
    const queryParams = queryString.parse(window.location.search);
    const { currId } = useGlobalState();
    const [currSymbol, setcurrSymbol] = useState("");
    const [CategoryData, setCategoryData] = useState([]);
    
    // const [queryParams, setQueryParams] = useState(queryString.parse(window.location.search));

    useEffect(() => {

        if (!isLoad) {
            console.log(queryParams)

            let userData = JSON.parse(localStorage.getItem("userData"));
            if (userData) {
                setUserId(userData.id);
                setIsLogin('yes');
            } else {
                let old_guest_id = localStorage.getItem('itell_guest_user_id');

                if (!old_guest_id) {
                    let uniqe_id = Math.floor(Math.random() * 90000) + 10000;
                    localStorage.setItem('itell_guest_user_id', uniqe_id);
                    setUserId(uniqe_id);
                } else {
                    setUserId(old_guest_id);
                }

            }
            getBook();
            if (queryParams.isEdit && queryParams.isEdit == 'yes') {
                let edit_book_data = localStorage.getItem('edit_book');
                edit_book_data = JSON.parse(edit_book_data);
                if (edit_book_data) {
                    setBookValues(JSON.parse(edit_book_data.book_values));
                    setValues(JSON.parse(edit_book_data.book_values));
                }
            } else {
                localStorage.removeItem('edit_book');
                console.log(queryParams)
            }
            if (queryParams.isReEdit && queryParams.isReEdit == 'yes') {
                setBookValues(queryParams);

            }
        }

        gettypeoptions();
    }, [isLoad, currId]);

    
    
    const handleChange = (e) => {

        // const { name, value } = e.target;
        // const name = e.target.name
        // const value = e.target.value
        // setValues({
        //     ...values,
        //     [name]: value,
        // });

        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });

        // console.log(values)
        // console.log(book_values)
        // console.log(e.target.value)

        if (e.target.name === 'price_type') {
            // setLowestPriceType(e.target.value)
            const divElement = document.querySelector('.price_div');
            divElement.textContent = currSymbol + ' ' + price_type_dict[e.target.value];
            localStorage.setItem('selected_price_type',e.target.value)
        }
    };
    // const handleClear = (e) => {
    //     window.location.reload();
    // }
    const checkRadio = (e) => {
        console.log(Object.keys(values))
        delete values.isReEdit;
        delete values.book_id;
        delete values.book_orientation;
        delete values.user_id;
        delete values.is_guest_user;
        if (localStorage.getItem('userData') == null) {
            console.log('userData is not present');
            localStorage.setItem('selected_book_id', bookData.slug)
            localStorage.setItem('selected_gender', values.your_gender)
            localStorage.setItem('selected_name',values.your_name)
            localStorage.setItem('selected_price_type',values.price_type)
            localStorage.setItem('is_first_login',true)
            navigate("/login");
            
        }
        else{
        let form_slugs = [];
        form_fields.map(form_field => (
            //console.log(form_field.slug)
            form_slugs.push(form_field.slug)
        ));

        console.log(form_slugs.length)

        
        
        

        // if (Object.keys(values).length != form_slugs.length) {
        //     console.log(Object.keys(values).length)
        //     console.log(form_slugs.length)
        //     alert("All fields are required.1");
        //     return false;
        // }

        // var count = 0;
        // console.warn(values);
        // for (const property in values) {

        //     if (values[property] === '') {
        //         count++;
        //     }
        // }

        // if (count > 0) {
        //     alert("All fields are required");
        //     return false;
        // }
        const emptyInputs = form_fields.filter((form_field) => {
            return form_field.type === 'input' && !values[form_field.slug];
        });
        console.log('emptyInputs', emptyInputs)

        var count = 0;

        for (const property in values) {

            if (values[property] === '') {
                count++;
            }
        }
        console.log(values)
        if (emptyInputs.length > 0) {
            // alert('All fields are required.');
            if (emptyInputs.length === 2) {
                console.log(emptyInputs[0].value.length)
                if(emptyInputs[0].value.length === 0)
                {
                    var paragraph = document.getElementById("your_name");
                    paragraph.classList.add("border");
                    paragraph.classList.add("border-danger");
                    Comman.showMessage(`Write your name`, "error");
                    
                }
                if(emptyInputs[1].value.length === 0)
                {
                    var paragraph = document.getElementById("friend_name");
                    paragraph.classList.add("border");
                    paragraph.classList.add("border-danger");
                    Comman.showMessage(`Write Friend name`, "error");
                }
            }
            else
            {
                console.log(emptyInputs[0].slug)
                var paragraph = document.getElementById(emptyInputs[0].slug);
                paragraph.classList.add("border");
                paragraph.classList.add("border-danger");
                console.log('Write your name')
                if(emptyInputs[0].slug == "your_name"){
                    Comman.showMessage(`Write your name`, "error");
                    }
                    else{
                        Comman.showMessage(`Write friend name`, "error");
                    }
            }

            
            if (values['your_gender'] === '') {
                console.log('Please select gender', count)
                Comman.showMessage(`Please select gender`, "error");
            }
            // console.log(form_fields.length === 4)
            // if (form_fields.length === 4){
            //     console.log(values['friend_name'] === '')
            //     if (values['friend_name'] === '') {
            //         Comman.showMessage(`Write friend name`, "error");
            //     }
            if (values['friend_gender'] === '') {
                // alert("All fields are required.");
    
                Comman.showMessage(`Please select friend gender`, "error");
                window.scrollTo(0, 0);
                return false;
            }
            // }

            window.scrollTo(0, 0);
            return false;
        }
        if (values['your_gender'] === '') {
            // alert("All fields are required.");

            Comman.showMessage(`Please select gender`, "error");
            window.scrollTo(0, 0);
            return false;
        }
        if (values['friend_gender'] === '') {
                // alert("All fields are required.");
    
                Comman.showMessage(`Please select friend gender`, "error");
                window.scrollTo(0, 0);
                return false;
            }
        
        // else if (count > 0) {
        //     // alert("All fields are required.");

        //     Comman.showMessage(`Please select gender`, "error");
        //     window.scrollTo(0, 0);
        //     return false;
        // }

        else {
            var paragraph = document.getElementById("your_name");
            // Add the class
            // paragraph.classList.add("d-none");
            paragraph.classList.remove("border");
            paragraph.classList.remove("border-danger");

        }
        

        if (bookData.id === 18) {
            console.log(values.your_gender)
            console.log(values.friend_gender)
            if (values.your_gender !== values.friend_gender) {
                if (values['friend_gender'] === '') {
                    // alert("All fields are required.");
        
                    Comman.showMessage(`Please select friend gender`, "error");
                    window.scrollTo(0, 0);
                    return false;
                }
                if (values.your_gender !== values.friend_gender) {
                    // alert("Please select same gender.");
                    Comman.showMessage(`Please select same gender`, "error");
                    window.scrollTo(0, 0);
                    return false;
                }
            } else {
                navigate("/preview-book/?book_id=" + bookData.id + "&slug=" + bookid + "&user_id=" + user_id + "&is_guest_user=" + is_login + "&book_orientation=" + bookData.book_orientation + "&" + serialize(values));
            }
        } else {
            navigate("/preview-book/?book_id=" + bookData.id + "&slug=" + bookid + "&user_id=" + user_id + "&is_guest_user=" + is_login + "&book_orientation=" + bookData.book_orientation + "&" + serialize(values));
        }
    }
    };

    // const gettypeoptions = () => {

    // }
    const isKeyValuePairExist = (array, key, value) => {
        console.log(array)
        console.log(key)
        console.log(value)
        for (let i = 0; i < array.length; i++) {
            if (array[i][key] === value) {
                return true; // Key-value pair found
            }
        }
        return false; // Key-value pair not found
    };
    const gettypeoptions = () => {
        Comman.callAPI("get", "/get-book-price/" + bookid + "?currency_id=" + currId).then((res) => {
            console.log(res)
            console.log(res.PriceTypeList[0].pricetype)

            if (typeof res !== "undefined") {


                setPriceTypeList({
                    price_type: res.PriceTypeList,
                });
                setAvlIn(res.PriceType)
                SetGender('Boy')
                setcurrSymbol(res.curr_symbol)
                
                console.log(res.PriceTypeList[0].pricetype)
                // setLowestPriceType(res.PriceTypeList[0].pricetype)
                setPriceTypeDict(res.PriceTypeDict)
                setValues({
                    ...values,
                    ['price_type']: res.PriceTypeList[0].pricetype,
                });
                if (localStorage.getItem("userData") !== null){
                    setLowestPriceType(localStorage.getItem("selected_price_type"))
                    console.log('price_type', queryParams.price_type)
                    if (queryParams.price_type !== undefined ){
                        console.log("localStorage.getItem('selected_price_type')",localStorage.getItem("selected_price_type"))
                        setLowestPrice(res.PriceTypeDict[localStorage.getItem("selected_price_type")])
                        
                    }else{
                        console.log('res.LowestPrice',res.LowestPrice)
                        setLowestPrice(res.LowestPrice)
                    }
                    
                }
                else{
                    localStorage.setItem('selected_price_type',res.PriceTypeList[0].pricetype)
                    setLowestPriceType(res.PriceTypeList[0].pricetype)
                    setLowestPrice(res.LowestPrice)
                }

                if (queryParams.isReEdit && queryParams.isReEdit == 'yes') {
                    let name = queryParams['your_name']
                    let gender = queryParams['your_gender']
                    setValues({
                        ...values,
                        ['your_name']: name,
                        ['your_gender']: gender
                    });

                    // if (form_fields.length === 4){
                    //     setValues({
                    //         ...values,
                    //         ['friend_name']: '',
                    //         ['friend_gender']: ''
                    //     });
                    // }
                }


                // setValues({
                //     ...values,
                //     ['your_gender']: gender,
                //     ['your_name']: name
                // });


            }
        });
    }

    const getBook = () => {
        //console.log(queryParams.your_name);
        // const search_data = {
        //     'your_name': queryParams.your_name,
        //     'your_gender': queryParams.your_gender,

        // }
        if (Object.keys(queryParams).length > 0) {
            console.log(queryParams)
            Comman.callAPI("get", "/get-book-detail/" + bookid + '?your_name=' + queryParams.your_name + '&your_gender=' + queryParams.your_gender, '').then((res) => {
                if (typeof res !== "undefined") {
                    console.log("BookData: "+ res)
                    setBookData(res);
                    setFormFields(res.form_fields)
                    console.log('form_fields: ' + res.form_fields)
                    // setBookImage(res.books_gallery_image);

                    res.books_gallery_image.map(book => (
                        // console.log(book)
                        book_images.push(book)
                    ))
                    setIsLoad(true);

                }
            });
        } else {
            Comman.callAPI("get", "/get-book-detail/" + bookid + '?your_name=Arya' + '&your_gender=', '').then((res) => {
                if (typeof res !== "undefined") {
                    console.log(res)
                    setBookData(res);
                    setFormFields(res.form_fields)
                    const keyValueExists = isKeyValuePairExist(res.form_fields, 'slug', 'friend_gender')
                    if (keyValueExists == true) {
                        setValues({
                            ...values,
                            ['friend_gender']: '',
                            ['friend_name']: '',
                            ['price_type']: "PDFPrice"
                        });
                    }
                    console.log(res.form_fields)
                    console.log(res.form_fields.length)
                    // setBookImage(res.books_gallery_image);

                    res.books_gallery_image.map(book => (
                        // console.log(book)
                        book_images.push(book)
                    ))
                    setIsLoad(true);

                }
            });
        }
    }

    const handleAddToCart = (e) => {
        e.preventDefault();
        console.log(form_fields.length);
        let form_slugs = [];
        form_fields.map(form_field => (
            //console.log(form_field.slug)
            form_slugs.push(form_field.slug)
        ));

        // if(Object.keys(values).length != form_slugs.length){
        //     alert("All fields are required.3");
        //     return false;
        // }

        const emptyInputs = form_fields.filter((form_field) => {
            return form_field.type === 'input' && !values[form_field.slug];
        });
        console.log('emptyInputs', emptyInputs)

        var count = 0;

        for (const property in values) {

            if (values[property] === '') {
                count++;
            }
        }
        console.log(values)
        if (emptyInputs.length > 0) {
            // alert('All fields are required.');

            var paragraph = document.getElementById("your_name");
            paragraph.classList.add("border");
            paragraph.classList.add("border-danger");

            console.log('Write your name')


            Comman.showMessage(`Write your name`, "error");
            if (values['your_gender'] === '') {
                console.log('Please select gender', count)
                Comman.showMessage(`Please select gender`, "error");
            }
            if (form_fields.length === 4){
                if (values['friend_name'] === '') {
                    Comman.showMessage(`Write friend name`, "error");
                }
                if (values['friend_gender'] === '') {
                    // alert("All fields are required.");
        
                    Comman.showMessage(`Please select friend gender`, "error");
                    window.scrollTo(0, 0);
                    return false;
                }
            }


            window.scrollTo(0, 0);
            return false;
        }
        else if (values['your_gender'] === '') {
            // alert("All fields are required.");
            console.log('Please select gender', count)
            Comman.showMessage(`Please select gender`, "error");
            window.scrollTo(0, 0);
            return false;
        }
        else if (form_fields.length == 4){
            if (values['friend_gender'] === '') {
                // alert("All fields are required.");
    
                Comman.showMessage(`Please select friend gender`, "error");
                window.scrollTo(0, 0);
                return false;
            }
        }
        // else if (count > 0) {
        //     // alert("All fields are required.");

        //     Comman.showMessage(`Please select gender`, "error");
        //     window.scrollTo(0, 0);
        //     return false;
        // }

        else {
            var paragraph = document.getElementById("your_name");
            // Add the class
            // paragraph.classList.add("d-none");
            paragraph.classList.remove("border");
            paragraph.classList.remove("border-danger");

        }

        // var count = 0;
        // for (const property in values) {

        //     if (values[property] === '') {
        //         count++;
        //     }
        // }

        // if (count > 0) {
        //     // alert("All fields are required.");
        //     // var gender_p = document.getElementById("gender_p");
        //     // // Add the class
        //     // gender_p.classList.remove("d-none");
        //     // gender_p.classList.add("border");
        //     // gender_p.classList.add("border-danger");
        //     Comman.showMessage(`Please select gender`, "error");
        //     window.scrollTo(0, 0);
        //     return false;
        // }else{
        //     var gender_p = document.getElementById("gender_p");
        //     // Add the class
        //     gender_p.classList.add("d-none");
        // }
        if (bookData.id === 18) {
            if (values['friend_gender'] === '') {
                // alert("All fields are required.");
    
                Comman.showMessage(`Please select friend gender`, "error");
                window.scrollTo(0, 0);
                return false;
            }
            if (values.your_gender !== values.friend_gender) {
                // alert("Please select same gender.");
                Comman.showMessage(`Please select same gender`, "error");
                window.scrollTo(0, 0);
                return false;
            }
        }
        let userData = JSON.parse(localStorage.getItem("userData"));
        let edit_data = JSON.parse(localStorage.getItem("edit_book"));
        let user_id1 = user_id;
        let is_guest_user = 'yes';
        let is_edit_cart = 'no';
        let cart_id = '';
        if (userData) {
            //user_id = userData.id;
            is_guest_user = 'no'
        }
        if (edit_data) {
            is_edit_cart = 'yes'
            cart_id = edit_data.id
        }
        let queryParamsApi = Object.assign({}, queryParams);
        delete queryParamsApi.book_id;
        delete queryParamsApi.book_orientation;
        delete queryParamsApi.is_guest_user;
        delete queryParamsApi.user_id1;

        delete values.book_id;
        delete values.book_orientation;
        delete values.is_guest_user;
        delete values.isReEdit;
        delete values.user_id;
        if (localStorage.getItem("userData") !== null){
            console.log('user_data')
            var selectedPriceType = localStorage.getItem("selected_price_type")
            localStorage.setItem('selected_price_type',selectedPriceType)
        }
        // else{
        //     console.log('user_data_null')
        //     values['price_type'] = LowestPriceType
        // }
        if (!('price_type' in values)) {
            values['price_type'] = LowestPriceType
        }

        const cart_data = {
            'book_id': bookData.id,
            'user_id': user_id,
            'is_guest_user': is_guest_user,
            'is_edit_cart': is_edit_cart,
            'cart_id': cart_id,
            'set_unique_id': '',
            'book_values': values,
            'price_type': values.price_type,
            'your_gender': values.your_gender,
            'your_name': values.your_name,
            'currency_id': currId
        }
        console.log(cart_data)
        if (localStorage.getItem('userData') !== null) {
            Comman.callAPI("post", "/add-to-cart", cart_data).then((res) => {
                if (typeof res !== "undefined") {
                    console.log(res)
                    localStorage.setItem('cartCount', res.cart.length);
                    navigate("/checkout");
                }
            });
        } else {
            console.log('userData is not present');
            localStorage.setItem('selected_book_id', bookData.slug)
            localStorage.setItem('selected_gender', values.your_gender)
            localStorage.setItem('selected_name',values.your_name)
            
            localStorage.setItem('is_first_login',true)
            navigate("/login");
            
        }

        
    };
    const changeStyle = () => {
        console.log(style);
        if (read_content === 'Read More') {
            setStyle("");
            setStyle1("book_description1");
            setReadContent("Read Less");
        } else {
            setStyle("book_description");
            setStyle1("");
            setReadContent("Read More");
        }
    };
    return (
        <>
            <Helmet>
                <title>{props.title}</title>
            </Helmet>
            <Header />
            <section className="inner-page-wrapper name-book-wrapper d-flex flex-column">
                <div className="book-preview bg-light-gradient">
                    <div className="container-xl">
                        <div className="title mb-2 text-center">
                            <h2 className="title-text">Create your book <br />preview now</h2>
                        </div>
                        <div className="selection-module-wrapper">
                            <div className={`row justify-content-${form_fields.length === 4 ? 'right' : 'center' }`}>
                                
                                {form_fields.map((form_field) => (
                                    <div key={form_field.slug} className={`col-sm-${form_fields.length === 4 ? '6' : '4'} col-md-${form_fields.length === 4 ? '6' : '4'} col-lg-${form_fields.length === 4 ? 'auto' : '4'} text-center selection-module mb-3`}>
                                        {/* // <div class="col-sm-6 col-md-6 col-lg-auto text-center selection-module mb-3"> */}
                                        {form_field.type != 'radio' ?
                                            <h4 className="text-2xl font-primary selection-module-title">{form_field.lable}</h4>
                                            : form_fields.length == 2 ? <h4 className="text-2xl font-primary selection-module-title">&nbsp;</h4> : null}

                                        {form_field.type === 'input' ? (
                                            <div className="form-group text-center">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id={form_field.slug}
                                                    name={form_field.slug}
                                                    placeholder="eg. Sunil"
                                                    defaultValue={book_values[form_field.slug]}
                                                    onChange={handleChange}
                                                />
                                                <div>
                                                    <p id="name" className="text-danger d-none">This field is required</p>
                                                </div>
                                            </div>
                                        ) : null}

                                        {form_field.type === 'radio' ? (
                                            <div className="d-flex justify-content-between align-items-center w-auto">
                                                {form_field.value.map((f_value) => (
                                                    Object.keys(book_values).length > 0 ? (
                                                        <div className="custom-control custom-radio fancy-radio" key={f_value}>
                                                            <input
                                                                type="radio"
                                                                id={`${form_field.slug}_${f_value}`}
                                                                name={form_field.slug}
                                                                className="custom-control-input"
                                                                value={f_value}
                                                                onChange={handleChange}
                                                                // defaultChecked={book_values[form_field.slug] === f_value}
                                                            />
                                                            <label
                                                                className="custom-control-label font-secondary text-xl"
                                                                htmlFor={`${form_field.slug}_${f_value}`}
                                                            >
                                                                {f_value}
                                                            </label>
                                                        </div>
                                                    ) : (
                                                        <div className="custom-control custom-radio fancy-radio" key={f_value}>
                                                            <input
                                                                type="radio"
                                                                // defaultChecked={values[form_field.slug] === f_value}
                                                                id={`${form_field.slug}_${f_value}`}
                                                                name={form_field.slug}
                                                                className="custom-control-input"
                                                                value={f_value}
                                                                onChange={handleChange}
                                                            />
                                                            <label
                                                                className="custom-control-label font-secondary text-xl"
                                                                htmlFor={`${form_field.slug}_${f_value}`}
                                                            >
                                                                {f_value}
                                                            </label>
                                                        </div>
                                                    )
                                                ))}
                                                {form_fields.length != 4 ?
                                                <div className="col-auto">
                                                    <a className="btn btn-secondary btn-preview" onClick={checkRadio}>
                                                        Preview Book
                                                    </a>
                                                </div>: null}
                                                <br />
                                                <div>
                                                    <p id="gender_p" className="text-danger d-none">Please Select Gender</p>
                                                </div>
                                            </div>
                                        ) : null}





                                    </div>

                                ))}

                                {form_fields.length === 4 ? <div className="col-md-12 text-right selection-module mb-3" >
                                    <a className="btn btn-secondary btn-preview" onClick={checkRadio} style={{ marginTop: '-21%', marginRight: '8%' }}>
                                        Preview Book
                                    </a>

                                </div> : null}




                            </div>
                        </div>

                    </div>
                </div>
                <div className="info-module ">
                    <div className="container-xl">
                        <div className="row justify-content-between align-items-center">
                            <div className="col-lg-6 mb-4 mb-lg-0">
                                <div className="fancy-card name-book-card mx-auto">

                                    {book_images.length && (

                                        <OwlCarousel
                                            className="name-book-carousel owl-carousel owl-theme"
                                            items={1}
                                            loop
                                            dots={true}
                                            margin={0}
                                            autoplay={false}
                                            nav={false}
                                            callbacks={true}
                                        >

                                            {book_images.map(book => (
                                                <div className="item" key={book.id}>
                                                    <img src={book.gallery_image} alt="book" />
                                                </div>
                                            ))}
                                        </OwlCarousel>
                                    )}
                                </div>
                            </div>
                            <div className="col-lg name-book-wrap">
                                <div className="title">
                                    <h2 className="title-text title-text-lg mb-0">{bookData.book_name}</h2>
                                </div>
                                <label className="" >
                                <b>Evolve Skills- {bookData.category_title}</b>
                                    </label>
                                    {/* <div className={"content des_content book_description " + style + " " + style1} dangerouslySetInnerHTML={{ __html: bookData.category_title }}>
                                    </div> */}
                                <div className={"content des_content book_description " + style + " " + style1} dangerouslySetInnerHTML={{ __html: bookData.book_description }}>
                                </div>
                                <a className="moreless-button" href="javascript:void(0)" onClick={changeStyle}>{read_content}</a>
                                {/* <p>{bookData.book_description}</p> */}
                                <div className="row row-devider name-book-summary">
                                    <div className="col-auto">
                                        <div className="media align-items-center">
                                            <div className="media-left mr-3">
                                                <img src={kids} alt="kids" width="48" />
                                            </div>
                                            <div className="media-body">
                                                <h5 className="text-base font-primary mb-0">{bookData.age_range_value}</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <div className="media align-items-center">
                                            <div className="media-left mr-3">
                                                <img src={pages} alt="pages" width="48" />
                                            </div>
                                            <div className="media-body">
                                                <h5 className="text-base font-primary mb-0">{bookData.pages_number} pages</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row name-book-summary align-items-center" title="Available In">

                                    <div className="media-left ml-3">
                                        <img src={open_book} alt="openbook" width="48" />
                                    </div>
                                    <div className="media-body">
                                        <h4 className="mb-0">{avlin}</h4>
                                    </div>

                                </div>
                                <div className="row  align-items-center">
                                    <div className='dropdown col-5'>
                                        <label style={{ display: 'block', textAlign: 'left', fontSize: '23px' }}>
                                            Choose Book Format
                                        </label>
                                        <select className="select" id="gender2"
                                         onChange={handleChange} name="price_type"
                                         {...(queryParams?.price_type && { value: queryParams.price_type })}>
                                            {/* {options.map} */}
                                            {price_type_list.price_type.map(price_type => (

                                                <option value={price_type.pricetype}>{price_type.pricedesc}</option>

                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-3">
                                        <h4 className="font-primary text-2xl text-dark price_div" style={{ marginTop: '52px' }}>
                                            {currSymbol} {LowestPrice}
                                            {/* {bookData.book_price} */}
                                        </h4>
                                    </div>
                                    <div className="col-4 mb-2 mt-4">
                                        <button className="btn btn-success" onClick={handleAddToCart} style={{ fontSize: '26px', padding: '10px 20px', marginTop: '25px' }}>
                                            Add to Cart
                                        </button>
                                    </div>


                                </div>
                                <div className="row align-items-center">

                                    {/* <div className="col-auto mb-2 mt-4">
                                        <button className="btn btn-success" onClick={handleAddToCart}>
                                            Add to Cart
                                        </button>
                                    </div> */}
                                    {/* <div className="col-auto mb-2 mt-4">
                                        <a className="btn btn-secondary btn-preview" onClick={checkRadio}>
                                            Preview Book
                                        </a>
                                    </div> */}

                                    {/* <div className="col-auto mb-2"><button type="reset" className="btn btn-secondary btn-clear" onClick={handleClear}>
                                        Clear
                                    </button></div> */}
                                </div>


                            </div>

                        </div>

                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}
function serialize(obj) {
    var str = [];
    for (var p in obj) {
        if (p in obj) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
    }
    return str.join("&");
}
export default BookDetail;