import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import queryString from "query-string";
function PageHeader() {
  const navigate = useNavigate();
  const queryParams = queryString.parse(window.location.search);
  const [isLoad, setIsLoad] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState({});
  const [values, setValues] = useState({
    your_name: "",
    your_gender: "",
  });
  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   //setErrors(validate(values));
  //   setIsSubmitting(true);
  // };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };
  const handleBoyClick = (e) => {
    setErrors(validate(values));
    let error = validate(values);
    if(Object.keys(error).length == 0){
      values.your_gender = 'Boy';
      //navigate('/browse-books?'+serialize(values));
      window.location.href = '/browse-books?'+serialize(values);
    }
  };
  const handleGirlClick = (e) => {
    setErrors(validate(values));
    let error = validate(values);
    if(Object.keys(error).length == 0){
      values.your_gender = 'Girl';
      //navigate('/browse-books?'+serialize(values));
      window.location.href = '/browse-books?'+serialize(values)
    }
  };

  useEffect(() => {
    if (queryParams.your_name) {
      setValues((prevValues) => ({
        ...prevValues,
        your_name: queryParams.your_name,
      }));
    }
  }, []);

  return (
    <>
      <section className="browseBook-pageHeader page-header text-center d-flex justify-content-center">
        <div className="container-xl">
          <h2 className="page-title text-5xl">Create your child's tale now</h2>
          <div className="form-wrap">
            <div className="row row-sm justify-content-center align-items-center">
              <div className="col-auto">
                <div className="form-group mb-2">
                  <h4 className="font-primary text-2xl">
                    Write the child's name :
                  </h4>
                </div>
              </div>-
              <div className="col-md-3 mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="eg. Sunil"
                  name='your_name'
                  defaultValue={queryParams.your_name ? queryParams.your_name : ''}
                  onChange={handleChange}
                />
                {errors.your_name && <div className="invalid-feedback d-block">{errors.your_name}</div>}
              </div>
              <div className="col-auto mb-3">
                  <button type="button" name='boy' className="btn btn-primary boy-btn button-boy" onClick={handleBoyClick}>
                    Boy
                  </button>
              </div>
              <div className="col-auto mb-3">
                  <button type="button" name='girl' className="btn btn-success girl-btn button-girl" onClick={handleGirlClick}>
                    Girl
                  </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
function serialize(obj) {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
}
function validate(values) {
  let errors = {};
  if (!values.your_name) {
    errors.your_name = 'Please enter name';
  }

  return errors;
}
export default PageHeader;
