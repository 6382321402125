import React, { useState, useEffect } from 'react';
import Logo from './Logo';
import PrimaryNavebar from './PrimaryNavebar';
import LoginNabar from './LoginNabar';
import cartimage from '../images/assets/cart.svg';
import userimg from '../images/assets/user.png';
import searchimage from '../images/assets/search.png';
import { NavLink, useNavigate } from "react-router-dom";
import whatsapp from '../images/assets/whatsapp.png';
import email from '../images/assets/email.png';
import * as utils from "../Utils/comman";
import SearchResults from 'react-filter-search';
import { useGlobalState } from '../Context/currency-context';
import { Link } from "react-router-dom";
import logoimg from '../images/logo/evolve-icon.png';

function Header() {
    const navigate = useNavigate();
    const [isLogin, setIsLogin] = useState(false);
    const [searchValue, setSearchValue] = useState(false);
    const [searchData, setSearchData] = useState([]);


    const [userName, setUserName] = useState('');
    const [cartCount, setCartCount] = useState((localStorage.getItem('cartCount')) ? localStorage.getItem('cartCount') : 0);
    // const { currId, setCurrId } = useGlobalState(localStorage.getItem("curremcy_id"));
    const { currId, setCurrId } = useGlobalState();
    const { currDesc } = useGlobalState();
    const { currData, setCurrData } = useGlobalState();
    // const currency_id = localStorage.getItem("curremcy_id")
    const handleLogout = (e) => {
        localStorage.removeItem('auth_access_token');
        localStorage.removeItem('userData');
        //navigate("/");
        window.location.href = "/";
    }
    const handleSearchChange = (e) => {
        console.log("aa");
        const { value } = e.target;
        setSearchValue(value);

    }

    const getCurrencyData = (e) => {
        utils.callAPI("get", "/currency", '').then((res) => {
            if (typeof res !== "undefined") {
                console.log(res)
                setCurrData(res);
            } else {
                setCurrData([]);
            }
        });
    }

    const handleCurrencyChange = (e) => {
        console.log(e.target.value)

        // let currency_id = localStorage.getItem('currency_id')
        setCurrId(e.target.value)
        console.log(currId)

        // console.log(currId)
        // localStorage.setItem("currency_id", e.target.value)
        // window.location.reload();


    }

    useEffect(() => {
        if (utils.isAuthenticated()) {
            setTimeout(function () {
                let u_data = JSON.parse(localStorage.getItem("userData"));
                if (u_data) {
                    setUserName(u_data.name)
                }
            }, 500);


            setIsLogin(true);
        } else {
            setIsLogin(false);
        }
    }, [currId]);

    useEffect(() => {

        if (searchValue) {
            const data = {
                title: searchValue,
            }
            utils.callAPI("post", "/book-search", data).then((res) => {
                if (typeof res !== "undefined") {
                    setSearchData(res);
                } else {
                    setSearchData([]);
                }
            });
        } else {
            setSearchData([]);
        }

        getCurrencyData();
    }, [searchValue]);
    return (

        <>
            <header id="header" className="header">
                <div className="navber-wrapper">
                    <div className="container-fluid">
                        <nav className="navigation-wrapper navbar-light bg-white navbar navbar-expand-lg row">
                            <Logo />

                            <PrimaryNavebar />
                            <div className="other-elem d-flex col-md-5 p-0">

                                <div className="col-auto mt-2">

                                    <a className="btn btn-primary boy-btn" target='_blank' href="mailto:info@itellmytale.com"><img src={email} alt='mail' /></a>
                                </div>
                                <div className="col-auto mt-2">

                                    <a className="btn btn-success girl-btn" target='_blank' href="https://api.whatsapp.com/send?phone=917985414447&text=Hi&type=phone_number&app_absent=0"><img src={whatsapp} alt='whatsapp' /></a>
                                </div>
                                <div className="col-auto mt-2">

                                    <select className="select" id="currency" onChange={handleCurrencyChange} name="currency">

                                        {currData.map(data => (

                                            <option selected={parseInt(currId) === parseInt(data.id) ? "selected" : false} value={data.id}>{data.icon} ({data.name})</option>

                                        ))}
                                    </select>
                                </div>
                                <div className="cart-box mr-3">
                                    
                                    {isLogin ? 
                                    <Link to={`/checkout`} className="cart-link">
                                        <img src={cartimage} width="30" />
                                        <span className="badge">{cartCount}</span>
                                    </Link > : 
                                    <Link to={`/login`} className="cart-link">
                                        <img src={cartimage} width="30" />
                                        <span className="badge">{cartCount}</span>
                                    </Link >
                                    }
                                </div>
                                

                                <div className="d-flex align-items-center hi-user m-0 mr-4" style={{width:"170px"}}>
                                    <NavLink to="/login" className={!isLogin ? "cart-link" : "cart-link d-none"}>
                                        <img src={userimg} className="mr-2" width={24} alt="" />
                                    </NavLink>
                                    <div class="dropdown show">
                                        <NavLink to="" className={isLogin ? "cart-link dropdown-toggle" : "cart-link dropdown-toggle d-none"} data-toggle="dropdown" aria-expanded="false">
                                            <img src={userimg} className="mr-2" width={24} alt="" /> Hi, <span>{userName}</span>
                                        </NavLink>
                                        <LoginNabar />

                                    </div>

                                </div>



                                <button className="navbar-toggler d-lg-none border-0" type="button" data-toggle="collapse"
                                    data-target="#mainNavigation" aria-controls="mainNavigation" aria-expanded="false"
                                    aria-label="Toggle navigation"> <span className="navbar-toggler-icon"></span> </button>
                                <div className=''>
                                    <Link className="navbar-brand" style={{marginRight:"0px"}} to="/evolve">
                                        <img src={logoimg} className="img-fluid" style={{ width: "95px" }} alt="logo" />
                                    </Link>
                                </div>
                            </div>

                        </nav>
                    </div>
                </div>
            </header>
        </>
    );
}

export default Header;