import React from "react";
import { QuickLinksMenu } from "../Utils/QuickLinksMenu";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
function QuickLinksNavebar() {
  return (
    <>
      <div className="col-md col-6">
        <div className="footer-widget" style={{marginLeft:"30px"}}>
          <h5 className="footer-widget-title text-2xl">Quick Links</h5>
          <ul className="footer-widget-links list-inline">
            {QuickLinksMenu.map((item, index) => {
              if (!("comp" in item)) {
                return (
                  <li key={index} className="nav-item">
                  <HashLink to={item.path}>{item.title}</HashLink>
                    {/* <a href={item.path} className={item.cName}>
                      {item.title}
                    </a> */}
                  </li>
                );
              } else {
                return (
                  <li key={index} className="nav-item">
                  
                    <NavLink key={index} to={item.path} className={item.cName}>
                      <span>{item.title}</span>
                    </NavLink>
                  </li>
                );
              }
            })}
          </ul>
        </div>
      </div>
    </>
  );
}
export default QuickLinksNavebar;
