import React from "react";
//import whyusimg from '../../images/assets/why-us.png';
//import arrow from '../../images/assets/arrow.svg';
function PageHeader(props) {
    return (
        <>
            <section className="page-header text-center d-flex justify-content-center">

                <div className="container-xl">
                    <h2 className="page-title">About us</h2>
                    <h4 className="page-subtitle">{props.subTitle}</h4>
                </div>
            </section>
        </>
    );
}
export default PageHeader;