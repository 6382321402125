import React from "react";
//import whyusimg from '../../images/assets/why-us.png';
//import arrow from '../../images/assets/arrow.svg';
function PageHeader() {
    return (
        <>
            <section className="page-header text-center d-flex justify-content-center">

                <div className="container-xl">
                    <h2 className="page-title">Complete Your Order</h2>
                </div>
            </section>
        </>
    );
}
export default PageHeader;