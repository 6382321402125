import React, {useState, useEffect} from 'react';
import * as Comman from "../../Utils/comman";
import { Carousel } from 'react-bootstrap';
// import banner1 from '../../images/assets/banner7.jpg';
// import banner2 from '../../images/assets/banner9.jpg';
// import new_year_banner from '../../images/assets/banner-new-year1.jpg'

//import banner3 from '../../images/assets/bnr1.jpg';
import arrowprev from '../../images/assets/arrow-prev.png';
import arrownext from '../../images/assets/arrow-next.png';

function Banner() {
    const prevIcon = <img src={arrowprev} alt="arrow" />;
    const nextIcon = <img src={arrownext} alt="arrow" />;

    const [banner_list, setBannerList] = useState({
        banner: [],
    });
   
    useEffect(() => {
        getBanners();

    }, []);
   
    const getBanners = () => {
        Comman.callAPI("get", "/banner").then((res) => {
            console.log(res)
            if (typeof res !== "undefined") {
                setBannerList({
                    banner: res,
                });
            }
        });
    }
    return (
        <>
            <section className="banner-wrapper">
            
                <Carousel controls="true" nextLabel="" nextIcon={nextIcon} prevLabel="" prevIcon={prevIcon} indicators={false}>
                {banner_list.banner.map(banner => (
                    <Carousel.Item interval={3000}>
                        <img className="d-block w-100" src={banner.image} alt="First Slide" />
                        <div className="banner-caption">
                            <div className="container-xl">
                                <div className="row">
                                    <div className="col-md-auto pr-md-5">
                                        <div className="banner-text py-md-3 d-md-block">
                                         
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </Carousel.Item>
                ))}
                </Carousel>
            </section>
        </>
    );
}

export default Banner;