import React, { useState, useEffect }  from 'react';
import Logo from './Logo';
import plane from '../images/assets/plane.png';
import truck from '../images/assets/truck.png';
import insectimg from '../images/assets/insect.png';
import OurbookNavbar from './OurbookNavebar';
import QuickLinksNavebar from './QuickLinksNavebar';
import SocialNavbar from './SocialNavbar';
import * as Comman from "../Utils/comman";
function Footer() {
    let year = new Date().getFullYear();
    const [address, setAddress] = useState();
    const [contactNo, setContactNo] = useState();
    const [emailAddr, setEmailAddress] = useState();
    const [bookList, setBookList] = useState([]);
    useEffect(() => {
        getFooterContact();
    }, []);
    const getFooterContact = () => {
        Comman.callAPI("get", "/website-footer-link").then((res) => {
            if (typeof res !== "undefined") {
                setAddress(res.address);
                setContactNo(res.contact_number);
                setEmailAddress(res.email_id);
            }
        });
    }
    const getBooks = (ageRange='',pageNumber = '', pageSize ='5') => {
        const data = {
            page: pageNumber,
            per_page: pageSize,
            age_range: ageRange
        }
        Comman.callAPI("post", "/all-books", data).then((res) => {
            if (typeof res !== "undefined") {
                setBookList(res.result);
            } 
        });
        
      }

      useEffect(()=>{
        console.log('itell');
      },[])
    return (
        <>
            <footer className="footer mt-5">
                <div className="footer-top">
                    <div className="container-xl">
                        <div className="plane-img"> 
                            <img src={plane} alt="plane" />
                        </div>
                        <div className="truck-img">
                            <img src={truck} alt="truck" />
                        </div>
                        <div className="row justify-content-between">
                            {/* <div className="col-md-3">
                                <div className="col-md mt-md-5 mb-5 mb-md-0">
                                    <div className="footer-logo-widget d-flex align-items-center h-100">
                                        <Logo />
                                    </div>
                                </div>
                            </div> */}

                            <div className="col-md-12">
                                <div className="row pl-lg-8 ">
                                    <OurbookNavbar />
                                    
                                    <QuickLinksNavebar />
                                       
                                    <div className="col-md">
                                        <div className="footer-widget" style={{marginLeft:"30px"}}>
                                            <h5 className="footer-widget-title text-2xl">Contact us</h5>
                                            <div className="footer-contacts address-widget">
                                                <div className="address" dangerouslySetInnerHTML={{__html: address}}>
                                                </div>
                                            </div>
                                            
                                            <div className="footer-contacts widget-mail">
                                                <p className="mb-1">Mail: <a className="text-body" href={"mailto:"+emailAddr}>{emailAddr}</a></p>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div className="col-md">
                                        <div className="footer-widget" style={{marginLeft:"30px"}}>
                                            <h5 className="footer-widget-title text-2xl">Social Media</h5>
                                            
                                            <div className="footer-contacts widget-phone">
                                                <p className="mb-1">WhatsApp: <a target="_blank" className="text-body" href="https://api.whatsapp.com/send?phone=917985414447&text=Hi&type=phone_number&app_absent=0">{contactNo}</a></p>
                                            </div>
                                            
                                            <SocialNavbar />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-copyright">
                    <div className="container-xl">
                        <div className="insect-img">
                            <img src={insectimg} alt="truck" />
                        </div>
                        <div className="copyright-wrap text-center pt-3 pb-2">
                            <p className="text-white mb-0"> &copy; Copyright@VASP EDUTECH PVT. LTD. | {year} All rights reserved. </p>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}

export default Footer;