import React from "react";
import { LoginSubmenu } from "../Utils/LoginSubmenu";
import { useNavigate  } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import { Outlet } from "react-router-dom";
function LoginNavbar() {
    const navigate  = useNavigate();
    const handleLogout = (e) => {
        localStorage.removeItem('auth_access_token');
        localStorage.removeItem('userData');
        window.location.href = "/";
        //navigate("/");
    }
    return(
        <>
            <div class="dropdown-menu">
                {LoginSubmenu.map((item, index) => {
                    if(!('comp' in item)){
                        return (
                            <a class="dropdown-item" href={item.externalurl} >My Account</a>
                        );
                    }else{
                        return (
                            <NavLink
                                key={index} to={item.path} className='dropdown-item'>
                                    <span>{item.title}</span>
                            </NavLink>
                        );
                    }
                })}
                <button onClick={handleLogout} className="dropdown-item">
                    Logout
                </button>
            </div>      
        </>
    )
}
export default LoginNavbar;