import React, { useState, useEffect, useCallback } from "react";
import { Helmet } from "react-helmet";
import * as Comman from "../Utils/comman";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import '../stylecss/auth.css';
import { NavLink, useNavigate } from "react-router-dom";
import { LoginSocialGoogle, LoginSocialFacebook } from 'reactjs-social-login';
import { GoogleLoginButton, FacebookLoginButton } from 'react-social-login-buttons';
function Login(props) {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSocailLoign, setisSocailLoign] = useState(false);
  const [values, setValues] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({});
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    let error = validate(values);
    setErrors(error);
    if (Object.keys(error).length === 0) {
      setIsSubmitting(true);
    } else {
      setIsSubmitting(false);
    }
  };
  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      const data = {
        email: values.email,
        password: values.password,

      }
      Comman.callAPI("post", "/login", data).then((res) => {
        setIsSubmitting(false);
        if (typeof res !== "undefined") {

          localStorage.setItem("auth_access_token", res.token);
          Comman.callAPI("get", "/user").then((res) => {
            if (typeof res !== "undefined") {

              localStorage.setItem("userData", JSON.stringify(res));
              if (localStorage.getItem('selected_book_id') !== null) {
                navigate("/book-detail/" + localStorage.getItem('selected_book_id') + '?isReEdit=yes&your_name=' + localStorage.getItem('selected_name') + '&your_gender=' + localStorage.getItem('selected_gender') + '&price_type=' + localStorage.getItem('selected_price_type'));

              }
              //navigate("/");/book-detail/
            }
          });
          navigate("/");
        }
      });

    }
  }, [isSubmitting]);
  const [provider, setProvider] = useState('')
  const [googleProfile, setGoogleProfile] = useState(null)
  const [facebookProfile, setFacebookProfile] = useState(null)
  useEffect(() => {
    if (isSocailLoign) {

      let data = '';
      if (provider === 'google') {
        console.log(googleProfile);
        data = {
          email: googleProfile.email,
          social_login_type: provider,
          social_login_id: googleProfile.id,
          name: googleProfile.name,
        }
      } else {
        data = {
          email: facebookProfile.email,
          social_login_type: provider,
          social_login_id: facebookProfile.id,
          name: facebookProfile.first_name + " " + facebookProfile.last_name,
        }
      }
      Comman.callAPI("post", "/social-login", data).then((res) => {
        setisSocailLoign(false);
        if (typeof res !== "undefined") {
          //console.log(res)
          localStorage.setItem("auth_access_token", res.token);
          navigate("/");
        }
      });
    }
  }, [isSocailLoign]);

  const onLoginStart = useCallback(() => {
    //alert('login start')
  }, [])
  return (
    <>
      <Helmet>
        <title>{props.title}</title>
      </Helmet>
      <Header />
      <section className="auth_section">
        <div className="container">
          <div className="auth_form">
            <div className="title">
              <h2 className="title-text title-text-lg">Login here</h2>
              <h4 className="page-subtitle">Create your account in seconds</h4>
            </div>
            <form className={(Object.keys(errors).length > 0) ? "needs-validation was-validated" : "needs-validation"} noValidate onSubmit={handleSubmit}>
              <div className="col-md-12 mb-3 p-0">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email id"
                  name="email"
                  onChange={handleChange}
                  required
                />
                {errors.email && <div className="invalid-feedback d-block">{errors.email}</div>}
              </div>
              <div className="col-md-12 mb-3 p-0">
                <input
                  type="password"
                  className="form-control"
                  placeholder="Password"
                  name="password"
                  onChange={handleChange}
                  required
                />
                {errors.password && <div className="invalid-feedback d-block">{errors.password}</div>}
              </div>
              <div className="col-auto mt-4 p-0">
                <button
                  type="submit"
                  className="btn btn-md btn-block btn-primary boy-btn"
                >
                  Submit
                </button>
              </div>

              <NavLink to="/forgot-password" className="forgot_text">Forgot Password</NavLink>
            </form>
            {/* client_id={'804836045342-i4910q82tegblo2naa9v3hdinf243drq.apps.googleusercontent.com'} */}
            <p className="another_page_text">
            <h4 className="page-subtitle">Have no account yet? <NavLink to="/signup">Sign up</NavLink></h4>
            </p>
            <LoginSocialGoogle
              isOnlyGetToken={false}
              client_id={'349285268690-b1clcd5banb9es21u4081rpn0f7cgccs.apps.googleusercontent.com'}
              onLoginStart={onLoginStart}
              scope={'https://www.googleapis.com/auth/userinfo.profile'}
              onResolve={({ provider, data }) => {
                setProvider(provider)
                setGoogleProfile(data)
                setisSocailLoign(true)
              }}
              onReject={(err) => {
                console.log(err)
              }}
            >
              <GoogleLoginButton />
            </LoginSocialGoogle>

            <LoginSocialFacebook
              appId={'330924579209657'}
              fieldsProfile={
                'id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender'
              }
              onLoginStart={onLoginStart}
              //onLogoutSuccess={onLogoutSuccess}
              //redirect_uri={'https://localhost:3000/dev'}
              onResolve={({ provider, data }) => {

                setProvider(provider);
                setFacebookProfile(data);
                setisSocailLoign(true)
              }}
              onReject={err => {
                console.log(err);
              }}
            >
              <FacebookLoginButton />
            </LoginSocialFacebook>
          </div>
        </div>

      </section>
      <Footer />
    </>
  );
}
function validate(values) {
  let errors = {};
  var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  if (!mailformat.test(values.email)) {
    errors.email = 'Please enter valid email.';
  }
  if (!values.password) {
    errors.password = 'Please enter password';
  }

  return errors;
}
export default Login;
