import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import * as Comman from "../Utils/comman";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
function OrderList(props) {
    const [isLoad, setIsLoad] = useState(false);
    const [orderData, setOrderData] = useState([]);
    const [show, setShow] = useState(false);
    //const [selOrderId, setSelOrderId] = useState();
    const [errors, setErrors] = useState({});
    const [values, setValues] = useState({
        order_cancel_reason: "",
        order_id: ""
    });
    useEffect(() => {
        if (!isLoad) {
            getData();
        }

    }, []);
    const handleChange = (e) => {
        
        const { name, value } = e.target;
        
        setValues({
          ...values,
          [name]: value,
        });
      };
    const getData = () => {
        Comman.callAPI("get", "/orders").then((res) => {
            if (typeof res !== "undefined") {
                setOrderData(res);
            }
        });
    }
    const handleClose = () => setShow(false);
    const handleShow = (e) => {
        setValues({'order_id': e.target.getAttribute('data-id')})
        setShow(true);
    }
    const handleCancelOrder = (e) => {
        e.preventDefault();
        let err = validate(values);
        setErrors(validate(values));
       
        if(Object.keys(err).length === 0){
            Comman.callAPI("post", "/cancel-order", values).then((res) => {
                if (typeof res !== "undefined") {
                    console.log(res);
                    setShow(false);
                    window.location.href = "/my-order"
                }
            });
        }
        //setErrors(validate(values));
        //setIsSubmitting(true);
      };
    return (
        <>
            <Helmet>
                <title>{props.title}</title>
            </Helmet>
            <Header />
            <section className="ordertabel">
                <h2 className="title-text title-text-lg">My Orders</h2>
                <div className="container table-responsive">
                    <table width="100%" border="0" cellpadding="0" cellspacing="0" className="table table-striped table-bordered">
                        <tbody>
                            <tr align="center">
                                <th>#</th>
                                <th>Order id</th>
                                <th>Date</th>
                                <th>Price</th>
                                <th>Order Status</th>
                                <th>&nbsp;</th>
                            </tr>
                            {Object.keys(orderData).length > 0 ? Object.keys(orderData).map(order => (
                                <tr align="center">
                                    <td>
                                        {Repeat(order)}
                                    </td>
                                    <td>{orderData[order].unique_order_id}</td>
                                    <td>{orderData[order].order_date}</td>
                                    <td>{orderData[order].curr_symbol} {orderData[order].total_amount}</td>
                                    <td>{orderData[order].order_status}</td>
                                    <td>
                                        <a href={"/order-detail?id=" + orderData[order].id} className="btn btn-md btn-block btn-primary boy-btn">Check Order</a>
                                        {orderData[order].isCancel != 'no' ?
                                            orderData[order].order_status != 'Cancelled' ?
                                                <a href="javascript:void(0)" className="btn btn-md btn-block btn-secondary boy-btn" onClick={handleShow} data-id={orderData[order].id}>
                                                    Cancel Order
                                                </a>
                                            : null 
                                        : null }
                                    </td>
                                </tr>
                            ))
                                :
                                <tr>
                                    <td colSpan={5} align="center">No data found</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </section>
            
            <Footer />
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Cancel Order</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                        <textarea class="form-control" name="order_cancel_reason" onChange={handleChange}></textarea> 
                        {errors.order_cancel_reason && <div className="invalid-feedback d-block">{errors.order_cancel_reason}</div>}   
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleCancelOrder}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
function Repeat(orderIndex) {
    let x = parseInt(orderIndex) + 1;
    return x;
}
function validate(values) {
    let errors = {};
    if (!values.order_cancel_reason) {
      errors.order_cancel_reason = 'Please enter cancel reason';
    }
  
    return errors;
}
export default OrderList;