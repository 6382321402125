import React from "react";
import Home from "../Pages/Home";
import BrowseBook from "../Pages/BrowseBook";
import PrivacyPolicy from "../Pages/PrivacyPolicy";
export const QuickLinksMenu = [
   
    {
        title: "Home",
        icon: "",
        path: "/",
        cName: "nav-link",
        comp: "",
    },
    {
        title: "Customize book",
        icon: "",
        path: "/#child_tale",
        cName: "nav-link",
        //comp: "",
    },
    {
        title: "About Us",
        icon: "",
        path: "/about-us",
        cName: "nav-link",
        comp: "",
    },
    {
        title: "FAQ",
        icon: "",
        path: "/faq",
        cName: "nav-link",
        comp: "",
    },
    {
        title: "Terms and conditions",
        icon: "",
        path: "/terms-conditions",
        cName: "nav-link",
        comp: <PrivacyPolicy title='Terms and conditions' />,
    },
    {
        title: "Shipping and Delivery Policy",
        icon: "",
        path: "/shipping-and-delivery-policy",
        cName: "nav-link",
        comp: <PrivacyPolicy title='Shipping and Delivery Policy' />,
    }
    
    
];  