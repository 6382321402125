import React,{useState, useEffect} from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import image1 from '../../images/assets/explore_banner.jpg';
import image2 from '../../images/assets/explore_banner2.jpg';

import arrowprev from '../../images/assets/arrow-prev-small.png';
import arrownext from '../../images/assets/arrow-next-small.png';
import arrow from '../../images/assets/arrow.svg';
function BrowseBooks() {
    const [book_list, setBookList] = useState([
        {
            key: 1,
            image: image1
        },
        {
            key: 2,
            image: image2
        },
       
        ]);
  return (
    <>
      <section className="browse-book-wrapper pt-3 pt-md-5">
        <div className="container-xl">
          <div className="row justify-content-between">
            <div className="col-md-6 order-1 order-md-2 mb-3 mb-md-0">
            <OwlCarousel
                className="browse-book-carousel owl-carousel owl-theme"
                items={1}
                loop
                dots={false}
                margin={0}
                autoplay={false}
                nav={true}
                navClass={['owl-prev d-flex justify-content-center align-items-center','owl-next d-flex justify-content-center align-items-center']}
                navText={[`<img src="${arrowprev}" />`,`<img src="${arrownext}">`]}
                
            >
                {book_list.map(book => (
                    <div className="item browse-book-item h-100 m-md-4" key={book.key}>
                        <img src={book.image} alt="book" />
                    </div>
                ))}
            </OwlCarousel>
              
            </div>
            <div className="col-md-6 order-2 order-md-1 pr-md-4">
              <div className="title">
                <h2 className="title-text text-dark text-5xl">
                  Explore the tales 
                  <br />
                  for your child
                </h2>
              </div>
              <p>
                Here are some fun filled and inspiring tales that your child will relate, 
                enjoy and learn from. What's more, while navigating through this tale, 
                your child plys the hero and his loved ones are in supporting cast.
              </p>
              <a href="/browse-books" className="btn btn-md btn-primary mt-3">
                Show more <img src={arrow} alt="arrow" />
              </a>
            </div>
          </div>
        </div>
      </section>
      
    </>
  );
}

export default BrowseBooks;
