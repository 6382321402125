import React, { useState, useEffect } from "react";
import * as Comman from "../../Utils/comman";
import kidswhite from '../../images/assets/kids-white.svg';
import pageswhite from '../../images/assets/pages-white.svg';
import arrow from '../../images/assets/arrow.svg';
import { useNavigate } from "react-router-dom";
function Subscribe() {
    const navigate = useNavigate();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errors, setErrors] = useState({});
    const [values, setValues] = useState({
        email: "",
    });
    
    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        setErrors(validate(values));
        setIsSubmitting(true);
    };
    useEffect(() => {
        if (Object.keys(errors).length === 0 && isSubmitting) {
          const data = {
            email: values.email,
            
    
          }
          Comman.callAPI("post", "/subscribe-newsletter", data).then((res) => {
            setIsSubmitting(false);
            if (typeof res !== "undefined") {
                console.log(res);
                setValues({email:''});
            }
          });
          
    
        }
      }, [isSubmitting]);
    return (
        <>
            <section className="subscribe_now pt-3 pt-md-5">
                <div className="container-xl">
                    <div className="subscribe_view">
                        <div className="subscribe_content">
                            <h4 className="title-text mb-0">Subscribe Now</h4>
                            <p>Stay informed and never miss an update by subscribing today.</p>

                        </div>
                        <div className="subscibe_form">
                            <form className={(Object.keys(errors).length > 0) ? "needs-validation was-validated" : "needs-validation"} noValidate onSubmit={handleSubmit}>
                                <div className="form-group">
                                    {console.log(values)}
                                    <input
                                        type="email"
                                        className="form-control"
                                        placeholder="Email id"
                                        name="email"
                                        onChange={handleChange}
                                        defaultValue={values.email}
                                        value={values.email}
                                        required
                                    />
                                    {errors.email && <div className="invalid-feedback d-block">{errors.email}</div>}
                                </div>
                                <div className="payment-button-box">
                                    <button className="btn btn-pay-now btn-primary" onClick={handleSubmit}>
                                        Send<img src={arrow} alt="arrow" />
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
function validate(values) {
    let errors = {};
    if (!values.email) {
      errors.email = 'Please enter email';
    }
    
  
    return errors;
}
export default Subscribe;