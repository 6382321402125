import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import queryString from "query-string";
function PageHeader() {
  const navigate = useNavigate();
  const queryParams = queryString.parse(window.location.search);
  const [isLoad, setIsLoad] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState({});
  const [values, setValues] = useState({
    your_name: "",
    your_gender: "",
  });
  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   //setErrors(validate(values));
  //   setIsSubmitting(true);
  // };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };
  const handleBoyClick = (e) => {
    setErrors(validate(values));
    let error = validate(values);
    if(Object.keys(error).length == 0){
      values.your_gender = 'Boy';
      navigate('/browse-books?'+serialize(values));
      // window.location.href = '/browse-books?'+serialize(values);
    }
  };
  const handleGirlClick = (e) => {
    setErrors(validate(values));
    let error = validate(values);
    if(Object.keys(error).length == 0){
      values.your_gender = 'Girl';
      navigate('/browse-books?'+serialize(values));
      // window.location.href = '/browse-books?'+serialize(values);
    }
  };

  useEffect(() => {
    if (queryParams.your_name) {
      setValues((prevValues) => ({
        ...prevValues,
        your_name: queryParams.your_name,
      }));
    }
  }, [values]);

  return (
    <>
      <section className="browseBook-pageHeader page-header text-center d-flex justify-content-center">
        <div className="container-xl">
          <h2 className="page-title text-5xl">Personalized Children’s Books</h2>
          <div className="form-wrap">
            <div className="row row-sm justify-content-center align-items-center">
              <div className="col-auto">
                <div className="form-group mb-2">
                  <div class="col-md-12 arrowiconS">
                            <h4 className="mb-4 page-title" style={{fontSize:'27px'}}>Step into a magical world tailored especially for {queryParams.your_name}</h4>
                            <h4 className="page-title" style={{fontSize:'22px'}}>Buy this book now to unlock the full adventure!</h4>
                            
                </div>
                </div>
              </div>-
              
              
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
function serialize(obj) {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
}
function validate(values) {
  let errors = {};
  if (!values.your_name) {
    errors.your_name = 'Please enter name';
  }

  return errors;
}
export default PageHeader;
