import React, { useState, useEffect, useCallback } from "react";
import HelmetExport, { Helmet } from "react-helmet";
import * as Comman from "../Utils/comman";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import PageHeader from "../Components/BrowseBook/PageHeader";
import LatestOffer from "../Components/Home/LatestOffer";
import { useNavigate } from "react-router-dom";
import queryString from "query-string";

function PaymentSuccess(props) {
  const navigate = useNavigate();
  const [isLoad, setIsLoad] = useState(false);
  const [orderItemDetail, setOrderItemDetail] = useState([]);
  const [orderId, setOrderId] = useState();
  const [transectionId, setTransectionId] = useState();
  const [shippingAddress, setShippingAddress] = useState();
  const [billingAddress, setBillingAddress] = useState();
  const [totalAmount, setTotalAmount] = useState();
  const [discountAmount, setDiscountAmount] = useState();
  const [currSymbol, setcurrSymbol] = useState();
  const queryParams = queryString.parse(window.location.search);

  useEffect(() => {
    localStorage.setItem('cartCount',0)

    if (!isLoad) {
      //setBookImages(initialState);
      getBookCoverImages();
    }
  });
  const getBookCoverImages = () => {
    // let rid = localStorage.getItem('razorpayOrderId');
    let rid = queryParams.order_id;
    if (!rid) {
      navigate("/");
    }

    Comman.callAPI("get", "/thanks-to-order/" + rid).then((res) => {

        console.log('res,',res);
      if (typeof res !== "undefined") {
        //let valdd = [...orderItemDetail];
        // {res.order_details.map(book => (
        //     //console.log(typeof book)
        //         valdd.push(book)
        // ))}
        setOrderItemDetail(res.order_details);
        setOrderId(res.unique_order_id);
        setTransectionId(res.razorpay_payment_id);
        setDiscountAmount(res.discount_amount);
        setcurrSymbol(res.currency_icon)
        setShippingAddress(
          res.full_name +
            "<br/>" +
            res.email +
            ",<br/>" +
            res.street_address_1 +
            ", " +
            (res.street_address_2 != null
              ? res.street_address_2 + ",<br/>"
              : "") +
            " " +
            res.city +
            ", " +
            res.state +
            ",<br/>" +
            res.country +
            ", " +
            res.post_code
        );
        setBillingAddress(
          (res.billing_street_address_1 != null
            ? res.billing_street_address_1 + ", "
            : "") +
            "" +
            (res.billing_street_address_2 != null
              ? res.billing_street_address_2 + ",<br/>"
              : "") +
            " " +
            (res.billing_city != null ? res.billing_city + ",<br/>" : "") +
            "" +
            (res.billing_state != null ? res.billing_state + ",<br/>" : "") +
            "" +
            (res.billing_country != null ? res.billing_country + ", " : "") +
            "" +
            (res.billing_post_code != null
              ? res.billing_post_code + "<br/>"
              : "")
        );
        setTotalAmount(res.total_amount);
        setIsLoad(true);
        let userData = JSON.parse(localStorage.getItem("userData"));
        if (!userData) {
          localStorage.removeItem("razorpayOrderId");
        }
        //setIsBookLoad(true);
      }
    });
  };
  return (
    <>
      <Helmet>
        <title>{props.title}</title>
      </Helmet>
      <Header />

      <section className="ordertabel">
        <div className="container table-responsive">
          <h2 className="title-text title-text-lg">Thank You</h2>
          <div className="orderbox">
            <div className="orderid">
              <h3>Order {orderId}</h3>
              <h3 className="price">{currSymbol} {totalAmount}</h3>
            </div>

            <hr />
            <p>Transaction Id: {transectionId}</p>
            <p>Discount Amount: {currSymbol} {discountAmount}</p>
            <div className="row">
              <div className="col-md-6">
                <p>Shipping address :</p>
                <div
                  dangerouslySetInnerHTML={{ __html: shippingAddress }}
                ></div>
              </div>
              <div className="col-md-6">
                <p>Billing address :</p>
                <div dangerouslySetInnerHTML={{ __html: billingAddress }}></div>
              </div>
            </div>
          </div>

          <h2 className="title-text title-text-lg mgtop50">
            Order timeline & Details
          </h2>
          <div className="orderbox ordertimeline">
            {orderItemDetail.map((book1) => (
              <div className="row">
                <div className="col-md-3">
                  <div className="book-image">
                    <a href={"/book-detail/" + book1.book_id}>
                      <img
                        src={
                          book1.book.image_server_path +
                          "/" +
                          book1.book.book_image
                        }
                        alt="book"
                        className="img-fluid"
                      />
                    </a>
                  </div>
                </div>

                <div className="col-md-9">
                  <h3>{book1.book.book_name}</h3>
                  <p>{currSymbol} {book1.book_price_on_order} </p>
                  <div
                    className="userbookdetail"
                    dangerouslySetInnerHTML={{ __html: book1.book_value_html }}
                  ></div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
export default PaymentSuccess;
