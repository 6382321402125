import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import * as Comman from "../Utils/comman";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import { NavLink, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import '../stylecss/auth.css';
function ResetPassword(props) {
  let {token} = useParams();
  let navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState({});
  const [values, setValues] = useState({
    password: "",
    c_password: "",
    token: ""
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setErrors(validate(values));
    setIsSubmitting(true);
  };
  useEffect(() => {
    if(!token){
      navigate("/");
    }else{
      setValues({token:token})
    }
  }, []);
  useEffect(() => {
        
    if (Object.keys(errors).length === 0 && isSubmitting) {
       
        const data = {
            token: values.token,
            password: values.password
        }
        Comman.callAPI('post', '/reset-password', data).then(
            res => {
                setIsSubmitting(false);
                //navigate("/");
            }
        )
        setIsSubmitting(false);
        //console.log("submit");
    }
}, [isSubmitting]);
  return (
    <>
      <Helmet>
        <title>{props.title}</title>
      </Helmet>
      <Header />
      <section className="auth_section">
        <div className="container">
          <div className="auth_form">
            <div className="title">
              <h2 className="title-text title-text-lg">Reset Password</h2>
              <h4 className="page-subtitle">This will help you reset your password</h4>
            </div>
            <form className={(Object.keys(errors).length > 0) ? "needs-validation was-validated" : "needs-validation"} noValidate onSubmit={handleSubmit}>
              <div className="col-md-12 mb-3 p-0">
                <input type="password" className="form-control" placeholder="New Password" name="password" onChange={handleChange} required />
                {errors.password && <p className="invalid-feedback d-block">{errors.password}</p>}
              </div>
              <div className="col-md-12 mb-3 p-0">
                <input type="password" className="form-control" placeholder="Confirm Password" name="c_password" onChange={handleChange} required />
                {errors.c_password && <p className="invalid-feedback d-block">{errors.c_password}</p>}
              </div>
              <div className="col-auto mt-4 p-0">
                <button type="submit" className="btn btn-md btn-block btn-primary boy-btn">Recover Password</button>
              </div>
              <a href="javascript:void(0)" className="forgot_text"></a>
            </form>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
function validate(values){
  let errors = {};
 
  if (!values.password) {
      errors.password = 'Please enter email';
  }
  if (!values.c_password) {
    errors.c_password = 'Confirm Password is required';
  } else if (values.c_password !== values.password) {
    errors.c_password = 'Confirm Password do not match';
  }
  return errors;
}
export default ResetPassword;