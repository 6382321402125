import React from 'react'

function Pagination({perPage, totalPages, totalCount, currentPage, paginate}) {
    //console.log(currentPage);
    const pageNumbers = [];

    for(let i = 1; i<= totalPages; i++){
        pageNumbers.push(i);
    }
    
    return (
        <div className="my-3 d-flex justify-content-center">
            <ul className="pagination justify-content-between">
                {/* <li className={"page-item " + ((hasPrevious) ? '' : 'disabled')}>
                    <a className="page-link" onClick={() => paginate(currentPage-1,perPage)} href="javascript:void(0)"  tabIndex="-1" aria-disabled={((hasPrevious) ? 'true' : '')}>Previous</a>
                </li> */}
                {pageNumbers.map(number => (
                    <li className={(currentPage == number) ? 'page-item active' : 'page-item'}>
                        <a className="page-link" href="javascript:void(0)" onClick={() => paginate('',number,perPage)}>{number}</a>
                    </li>
                ))}
                
                
                {/* <li className={"page-item " + ((hasNext) ? '' : 'disabled')}>
                    <a className="page-link" onClick={() => paginate(currentPage+1,perPage)} href="javascript:void(0)" aria-disabled={((hasNext) ? 'true' : '')}>Next</a>
                </li> */}
            </ul>
        </div>
         
    )
}

export default Pagination