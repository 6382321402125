import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import * as Comman from "../Utils/comman";
import Header from "../Components/Header";
import Pagination from '../Components/Pagination';
import kidswhite from '../images/assets/kids-white.svg';
import pageswhite from '../images/assets/pages-white.svg';
import arrow from '../images/assets/arrow.svg';
import Footer from "../Components/Footer";
import queryString from "query-string";
import { Link, useParams } from "react-router-dom";
import { useGlobalState } from '../Context/currency-context';

function EvolveDetail(props) {
    const queryParams = queryString.parse(window.location.search);
    console.log(queryParams);

    let { slug } = useParams();
    const [evolveData, setEvolveData] = useState([]);
    const [bookList, setBookList] = useState({
        books: [],
    });
    const [isLoad, setIsLoad] = useState(false);
    const { currId } = useGlobalState();
    
    useEffect(() => {
        if (!isLoad) {
            getEvolveData();
        }
    }, [isLoad]);

    useEffect(() => {
        if (isLoad) {
            getEvolveData();
        }
    }, [currId]);

    const getEvolveData = (ageRange = '', pageNumber = '', pageSize = '5') => {
        console.log(slug);
        const data = {
            page: pageNumber,
            per_page: pageSize,
            age_range: ageRange,
            your_name: queryParams.your_name,
            your_gender: queryParams.your_gender
        };
        Comman.callAPI("get", "/category-details/" + slug, '').then((res) => {
            if (typeof res !== "undefined") {
                console.log(res);
                setEvolveData(res.category);
                setIsLoad(true);
            }

            Comman.callAPI("get", "/get-book-lowest-price?currency_id=" + currId).then((lowestPriceRes) => {
                if (typeof lowestPriceRes !== "undefined") {
                    console.log(lowestPriceRes);

                    let url = currId ? "/get-book-lowest-price?currency_id=" + currId : "/get-book-lowest-price";

                    const updatedResults = res.result.map(item => {
                        return {
                            ...item,
                            book_price: lowestPriceRes[item.slug],
                            currency: lowestPriceRes[item.slug + '-curr-symbol']
                        };
                    });

                    if (typeof res !== "undefined") {
                        console.log(res);
                        console.log(updatedResults);

                        setBookList({
                            books: updatedResults,
                            perPage: res.limit,
                            totalPages: res.pages,
                            totalCount: res.total,
                            currentPage: res.current_page
                        });
                    }
                }
            });
        });
    };

    return (
        <>
            <Helmet>
                <title>{props.title}</title>
            </Helmet>
            <Header />
            <section className="evolve-des">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <h3>{evolveData.name}: </h3>
                            <p>{evolveData.short_desc}</p>
                        </div>
                        <div className="col-md-4">
                            <div className="evolve-img">
                                <img src={evolveData.image} alt={evolveData.name} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="evolve-imp">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h3>Why is it an important personality trait for a child?</h3>
                            <div dangerouslySetInnerHTML={{ __html: evolveData.description }}></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="inner-page-wrapper">
                <div className="info-module">
                    <div className="container-xl">
                        <div className="title mb-2 mb-md-3 text-left">
                            <h2 className="title-text text-5xl mb-0">Related Books</h2>
                        </div>
                        <div className="browse-books">
                            <div id="books_all">
                                <div className="row">
                                    {bookList.books.map(book => (
                                        <div key={book.slug} className="col-sm-6 col-md-6 col-lg-4 book-card-item">
                                            <div className="fancy-card book-card rounded-xl card h-100">
                                                <div className="card-header border-0 bg-transparent">
                                                    <div className="card-title mb-0">
                                                        <h4 className="text-dark text-xl mb-0">{book.book_name}</h4>
                                                    </div>
                                                </div>
                                                <div className="card-body text-center">
                                                    <div className="book-card-image">
                                                        <img src={book.book_image} alt="book" className="img-fluid" />
                                                    </div>
                                                    <div className="book-summary bg-success text-white">
                                                        <div className="row row-sm row-devider align-items-center">
                                                            <div className="col-auto">
                                                                <div className="media align-items-center">
                                                                    <div className="media-left mr-2">
                                                                        <img src={kidswhite} alt="kids" width="28" />
                                                                    </div>
                                                                    <div className="media-body">
                                                                        <h6 className="font-primary mb-0 text-base">{book.age_range} years</h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-auto">
                                                                <div className="media align-items-center">
                                                                    <div className="media-left mr-2">
                                                                        <img src={pageswhite} alt="pages" width="28" />
                                                                    </div>
                                                                    <div className="media-body">
                                                                        <h6 className="font-primary mb-0 text-base">{book.pages_number} pages</h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="content" dangerouslySetInnerHTML={{ __html: book.book_description.substr(0, 100) + '...' }}></div>
                                                    <h4 className="font-primary text-dark text-2xl">
                                                        {book.currency} {book.book_price}
                                                    </h4>
                                                </div>
                                                <div className="card-footer border-0 bg-transparent">
                                                    {Object.keys(queryParams).length > 0 ? (
                                                        <Link
                                                            to={{
                                                                pathname: `/book-detail/${book.slug}`,
                                                                search: `?isReEdit=yes&your_name=${queryParams.your_name}&your_gender=${queryParams.your_gender}`,
                                                            }}
                                                            className="btn btn-md btn-block btn-primary"
                                                        >
                                                            Select Book <img src={arrow} alt="arrow" />
                                                        </Link>
                                                    ) : (
                                                        <Link to={`/book-detail/${book.slug}`} className="btn btn-md btn-block btn-primary">
                                                            Select Book <img src={arrow} alt="arrow" />
                                                        </Link>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <Pagination perPage={bookList.perPage} totalPages={bookList.totalPages} totalCount={bookList.totalCount} currentPage={bookList.currentPage} paginate={getEvolveData} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}

export default EvolveDetail;
