import React, { useState, useEffect, useRef } from "react";
import * as Comman from "../Utils/comman";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import queryString from "query-string";
import HTMLFlipBook from 'react-pageflip';
import PageHeader from "../Components/BrowseBook/PreviewPageHeader";
import { ProgressBar } from 'react-loader-spinner';
//import arrowRight from '../images/assets/arrow-right.svg';
import arrowimage from '../images/assets/arrow-right.png';
import placeholderImage from '../images/assets/adventure-placeholder.png';
import { useNavigate } from "react-router-dom";
import { useGlobalState } from '../Context/currency-context';
//import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
function BookPreview(props) {
    const navigate = useNavigate();
    const [queryParams, setQueryParams] = useState(queryString.parse(window.location.search));
    const [isLoad, setIsLoad] = useState(false);
    // const [bookimages, setBookImages] = useState([placeholderImage, placeholderImage]);
    const [bookimages, setBookImages] = useState([]);
    const [isBookLoad, setIsBookLoad] = useState(false);
    const [isFrontPageLoad, setIsFrontPageLoad] = useState(false);
    const [bookPrice, setBookPrice] = useState("0.00");
    const [previewId, setPreviewId] = useState();
    const [price_type_list, setPriceTypeList] = useState({
        price_type: []
    });
    const [LowestPriceType, setLowestPriceType] = useState();
    const [LowestPrice, setLowestPrice] = useState();
    const { currId } = useGlobalState();
    const [currSymbol, setcurrSymbol] = useState("");

    const flipBook = useRef();
    //const bookimages = [];
    useEffect(() => {
        if (!isLoad) {
            //setBookImages(initialState);
            // gettypeoptions();
            getBookCoverImages();
            localStorage.removeItem('selected_price_type')

        }
    });
    useEffect(() => {
        if (isFrontPageLoad) {
            getBookImages(previewId);
        }
        gettypeoptions();
    }, [isFrontPageLoad, currId]);
    const prevButtonClick = (e) => {
        flipBook.current.pageFlip().flipPrev();
    };
    const nextButtonClick = (e) => {
        flipBook.current.pageFlip().flipNext();
    };


    const getBookCoverImages = () => {
        console.log('hi varun', queryParams)
        Comman.callAPI("post", "/create-book-front-cover", queryParams).then((res) => {
            if (typeof res !== "undefined") {
                setBookImages([]);
                let previewId = "";
                console.log('new data', res)
                res.image_data.map(book => (
                    //setBookImages([...bookimages, book.preview_image_full_url])
                    setBookImages(oldData => [...oldData, book.preview_image_full_url]),
                    setPreviewId(book.set_unique_id),
                    localStorage.setItem("itell_unique_id", book.set_unique_id)
                    //previewId = book.set_unique_id

                ));
                // setBookPrice(res.book_price);
                setIsFrontPageLoad(true);
                //getBookImages(previewId);

                setIsLoad(true);
                //setIsBookLoad(true);
            }
        });
    }
    const getBookImages = (previewId) => {
        queryParams.set_unique_id = previewId;
        Comman.callAPI("post", "/create-book-preview", queryParams).then((res) => {
            if (typeof res !== "undefined") {
                res.image_data.map(book => (
                    //setBookImages([...bookimages, book.preview_image_full_url])
                    setBookImages(oldData => [...oldData, book.preview_image_full_url])
                ));


                // setBookImages(oldData => [...oldData, custom])
                //setBookPrice(res.book_price);
                //setIsLoad(true);

                setIsBookLoad(true);
            }
        });
    }
    const gettypeoptions = () => {
        console.log(queryParams)
        Comman.callAPI("get", "/get-book-price/" + queryParams?.slug + "?currency_id=" + currId).then((res) => {
            console.log(res)
            console.log(res.PriceTypeList)
            if (typeof res !== "undefined") {
                if (localStorage.getItem("selected_price_type") !== null) {
                    console.log(res?.PriceTypeDict?.[localStorage.getItem("selected_price_type")])
                    queryParams.price_type = localStorage.getItem("selected_price_type")
                    setBookPrice(res?.PriceTypeDict?.[localStorage.getItem("selected_price_type")]);
                } else {
                    const tempdept = res?.PriceTypeDict?.[queryParams?.price_type]
                    setBookPrice(tempdept);
                    console.log('tempdept', tempdept)
                    if (typeof tempdept == "undefined") {
                        const tempdept = res.LowestPrice
                        setBookPrice(tempdept);
                        console.log('tempdept', tempdept)
                    }
                }
                setPriceTypeList({
                    price_type: res.PriceTypeList,
                });
                // setBookPrice(res.LowestPrice);

                setcurrSymbol(res.curr_symbol)


                setLowestPrice(res.LowestPrice)
                setLowestPriceType(res.PriceTypeList[0].pricetype)
                // setLowestPrice(tempdept)



            }
        });
        localStorage.removeItem('selected_book_id')
        localStorage.removeItem('selected_gender')
        localStorage.removeItem('selected_name')
        // localStorage.removeItem('selected_price_type')
        localStorage.removeItem('is_first_login')
    }
    const handleAddToCart = (e) => {
        e.preventDefault();
        let userData = JSON.parse(localStorage.getItem("userData"));
        let edit_data = JSON.parse(localStorage.getItem("edit_book"));
        let user_id = queryParams.user_id;
        let is_guest_user = 'yes';
        let is_edit_cart = 'no';
        let cart_id = '';
        if (userData) {
            //user_id = userData.id;
            is_guest_user = 'no'
        }
        if (edit_data) {
            is_edit_cart = 'yes'
            cart_id = edit_data.id
        }
        let queryParamsApi = Object.assign({}, queryParams);
        delete queryParamsApi.book_id;
        delete queryParamsApi.book_orientation;
        delete queryParamsApi.is_guest_user;
        delete queryParamsApi.user_id;
        delete queryParamsApi.slug;
        delete queryParamsApi.set_unique_id;

        console.log(queryParamsApi)
        if (!queryParamsApi.price_type) {
            queryParamsApi.price_type = LowestPriceType
        }
        // let price_type_val = (queryParamsApi.price_type?queryParamsApi.price_type:LowestPriceType)
        console.log('queryParamsApi.price_type', queryParamsApi.price_type);
        const cart_data = {
            'book_id': queryParams.book_id,
            'user_id': user_id,
            'is_guest_user': is_guest_user,
            'is_edit_cart': is_edit_cart,
            'cart_id': cart_id,
            'set_unique_id': previewId,
            'book_values': queryParamsApi,
            'price_type': queryParamsApi.price_type,
            'your_gender': queryParamsApi.your_gender,
            'your_name': queryParamsApi.your_name,
            'currency_id': currId
        }
        console.log(cart_data)

        Comman.callAPI("post", "/add-to-cart", cart_data).then((res) => {
            if (typeof res !== "undefined") {
                localStorage.setItem('cartCount', res.cart.length);
                navigate("/checkout");
            }
        });
    };
    const handleBookEdit = (e) => {
        e.preventDefault();
        let q_data = window.location.search;
        navigate("/book-detail/" + queryParams.slug + q_data + "&isReEdit=yes");
    }
    // const handleChange = (e) => {
    //     // console.log(e.target.value)
    //     // console.log(e.target.name)
    //     // // console.log(values)
    //     // // const { name, value } = e.target;
    //     // const name = e.target.name
    //     // const value = e.target.value
    //     setValues({
    //         ...values,
    //         [name]: value,
    //     });
    //     // console.log(values)
    //     // console.log(book_values)
    //     // console.log(e.target.value)


    //     if (e.target.name === 'bookPreview-title') {
    //         setLowestPriceType(e.target.value)
    //         const divElement = document.querySelector('.price_div');
    //         divElement.textContent = 'Printed Book ₹ '+{bookPrise}+' only ';
    //     }
    // };
    const handleChange = (e) => {

        if (e.target.name === 'price_type') {
            console.log('price_type', e)

            // console.log('queryParams', queryParams)
            const updatedParams = { ...queryParams };
            updatedParams.price_type = e.target.value;
            setQueryParams(updatedParams);
            setLowestPriceType(e.target.value)
            // console.log('updatedParams', updatedParams)
            const updatedQueryString = queryString.stringify(updatedParams);
            const decodedQueryString = decodeURIComponent(updatedQueryString);
            // console.log('decodedQueryString', decodedQueryString)
            navigate("/preview-book/?" + decodedQueryString);

            // gettypeoptions()

            const tempdept = price_type_list?.price_type[price_type_list?.price_type.findIndex(obj => obj.pricetype == e.target.value)];
            console.log('tempdept', tempdept?.price)
            setBookPrice(tempdept?.price)
            // setLowestPriceType(e.target.value)
        }
    }
    return (
        <>
            <Helmet>
                <title>{props.title}</title>
            </Helmet>
            <Header />
            <PageHeader />
            <section className="inner-page-wrapper previewBook-warpper d-flex flex-column">
                <div className="info-module">
                    <div className="container-xl">
                        <div class="col-md-12 arrowiconS">

                            <div class="first-pg" onClick={prevButtonClick}><img src={arrowimage} width="40" /></div>&nbsp;
                            <div class="last-pg" onClick={nextButtonClick}><img src={arrowimage} width="40" /></div>
                        </div>
                        {/* <div className="row justify-content-center">
                            <div className="col-md-12 col-lg-auto text-center mb-3"> */}
                        <div className="bookSlider">
                            {/* {!isBookLoad ? <div id="ajaxLoader">
                                <p>
                                    <span>
                                        <ProgressBar
                                            height="80"
                                            width="80"
                                            ariaLabel="progress-bar-loading"
                                            wrapperStyle={{}}
                                            wrapperClass="progress-bar-wrapper"
                                            borderColor='#e82365'
                                            barColor='#e82365'
                                        /><br />
                                        I tell my tale is at progress
                                    </span>
                                </p>
                            </div> : */}
                            {queryParams.book_orientation === 'landscape' ?
                                <HTMLFlipBook
                                    width={754}
                                    height={470}
                                    size="stretch"
                                    autoSize={true}
                                    drawShadow={true}
                                    showCover={true}
                                    ref={flipBook}
                                >
                                    {bookimages.map((book, i) => (
                                        <div className="demoPage">
                                            {/* <img src={book} className="bookImage"></img> */}
                                            {book.startsWith("https") ?
                                                <img src={book} className="bookImage2"></img>
                                                :
                                                // <React.Fragment>
                                                //     <h4 class="bookPreview-title text-dark font-primary text-2xl text-center">
                                                //         Printed Book ₹ {bookPrise} only
                                                //     </h4>
                                                //     <div className="row justify-content-center align-items-center">
                                                //         <div className="col-auto mb-3 mb-md-0">
                                                //             <button className="btn btn-fix btn-primary" onClick={handleAddToCart}>
                                                //                 Add to cart
                                                //             </button>
                                                //         </div>
                                                //         <div className="col-auto mb-3 mb-md-0">
                                                //             <a className="btn btn-fix btn-primary btn-secondary" onClick={handleBookEdit}>
                                                //                 Edit
                                                //             </a>
                                                //         </div>
                                                //     </div>
                                                // </React.Fragment>
                                                <></>
                                            }
                                        </div>
                                    ))}

                                </HTMLFlipBook> :
                                <HTMLFlipBook
                                    width={466}
                                    height={600}
                                    size="fixed"
                                    autoSize={true}
                                    drawShadow={true}
                                    showCover={true}
                                    ref={flipBook}

                                >
                                    {bookimages.map((book, i) => (
                                        // { book.startsWith("https") ? 
                                        <div className="demoPage">
                                            {book.startsWith("https") ?
                                                <img src={book} className="bookImage2"></img>
                                                :
                                                // <React.Fragment>
                                                //     <h4 class="bookPreview-title text-dark font-primary text-2xl text-center">
                                                //         Printed Book ₹ {bookPrise} only
                                                //     </h4>
                                                //     <div className="row justify-content-center align-items-center">
                                                //         <div className="col-auto mb-3 mb-md-0">
                                                //             <button className="btn btn-fix btn-primary" onClick={handleAddToCart}>
                                                //                 Add to cart
                                                //             </button>
                                                //         </div>
                                                //         <div className="col-auto mb-3 mb-md-0">
                                                //             <a className="btn btn-fix btn-primary btn-secondary" onClick={handleBookEdit}>
                                                //                 Edit
                                                //             </a>
                                                //         </div>
                                                //     </div>
                                                // </React.Fragment>
                                                <></>
                                            }
                                        </div>
                                    ))}


                                </HTMLFlipBook>



                            }
                            <div id="ajaxLoader" className={isBookLoad ? "d-none" : ''}>
                                <p>
                                    <span>
                                        <ProgressBar
                                            height="80"
                                            width="80"
                                            ariaLabel="progress-bar-loading"
                                            wrapperStyle={{}}
                                            wrapperClass="progress-bar-wrapper"
                                            borderColor='#e82365'
                                            barColor='#e82365'
                                        /><br />
                                        Your tale is getting created
                                    </span>
                                </p>
                            </div>
                            {/* </div>
                            </div> */}
                        </div>
                        {/* <h4 class="bookPreview-title text-dark font-primary text-2xl text-center">
                            Printed Book ₹ {bookPrise} only
                        </h4> */}
                        {/* new work */}
                        <div className="row justify-content-center align-items-center mt-3 mb-4">
                            <div className='dropdown col-6 mb-3 mb-md-0 '>
                                <label style={{ display: 'block', textAlign: 'right', fontSize: '23px' }}>
                                    Choose Book Format
                                </label>
                                <select className="select" id="gender2" style={{ float: 'right', padding: '15px 28px' }} onChange={handleChange}
                                    value={queryParams?.price_type ? queryParams?.price_type : ''}
                                    name="price_type">
                                    {/* {options.map} */}
                                    {price_type_list.price_type.map(price_type => (
                                        <option value={price_type.pricetype}>{price_type.pricedesc}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-6 mb-3 mb-md-0 text-start">
                                <h4 class="bookPreview-title text-dark font-primary text-2xl">
                                    {currSymbol} {bookPrice} only
                                    {/* {bookData.book_price} */}
                                </h4>
                            </div>
                        </div>
                        {/* new work */}
                        <div className="row justify-content-center align-items-center">
                            <div className="col-auto mb-3 mb-md-0">
                                <button className="btn btn-fix btn-success" onClick={handleAddToCart}>
                                    Add to cart
                                </button>
                            </div>
                            <div className="col-auto mb-3 mb-md-0">
                                <a className="btn btn-fix btn-primary btn-secondary" onClick={handleBookEdit}>
                                    Back
                                </a>
                            </div>
                            {/* <div className='col-auto mb-3 mb-md-0'>
                                        <select className="select" id="gender2" onChange={handleChange} name="price_type"> */}
                            {/* {options.map} */}
                            {/* {price_type_list.price_type.map(price_type => (

                                                <option value={price_type.pricetype}>{price_type.pricedesc}</option>

                                            ))}
                                        </select>
                                    </div> */}


                        </div>
                    </div>
                </div>
            </section>
            {/* <section className="inner-page-wrapper name-book-wrapper d-flex flex-column">
                <div className="info-module order-2 order-md-1">
                    <div className="container-xl">
                        <div className="bookSlider">
                            <HTMLFlipBook width={754}
                                height={470}
                                size="stretch"
                                autoSize={true}
                                drawShadow={true}
                                showCover={true}
                            >
                                {bookimages.map((book, i) => (
                                    <div className="demoPage">
                                        <img src={book} className="bookImage"></img>
                                    </div>
                                ))}
                                
                            </HTMLFlipBook>
                        </div>
                    </div>
                </div>
            </section>     */}

            <Footer />


        </>
    );
}
export default BookPreview;
