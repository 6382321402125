import React from "react";
import MyAccount from "../Pages/MyAccount";
import OrderList from "../Pages/OrderList";


export const LoginSubmenu = [
    {
      title: "My Account",
      icon: "",
      path: "/my-account",
      cName: "nav-link",
      comp: <MyAccount title={process.env.REACT_APP_SITE_TITLE}/>,
    },
    {
        title: "My order",
        icon: "",
        path: "/my-order",
        cName: "nav-link",
        comp: <OrderList title={`${process.env.REACT_APP_SITE_TITLE}: My Order`}/>,
    }
];