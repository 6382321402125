import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import * as Comman from "../Utils/comman";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import '../stylecss/auth.css';
function Signup(props) {
    const navigate = useNavigate();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [values, setValues] = useState({
        fullname: "",
        email: "",
        password: "",
    });
    const [errors, setErrors] = useState({});
    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        let error = validate(values); 
        setErrors(error);
        if(Object.keys(error).length === 0){
            setIsSubmitting(true);
        }else{
            setIsSubmitting(false);
        }
    };
    useEffect(() => {
        console.log("fjjfjjfj")
        console.log(Object.keys(errors).length);
        if (Object.keys(errors).length === 0 && isSubmitting) {
           
            const data = {
                name: values.fullname,
                email: values.email,
                password: values.password
            }
            Comman.callAPI('post', '/register', data).then(
                res => {
                    //console.log(res);
                    navigate("/login");
                    setIsSubmitting(false);
                }
            )
            setIsSubmitting(false);
            
        }
    }, [isSubmitting]);
    return (
        <>
            <Helmet>
                <title>{props.title}</title>
            </Helmet>
            <Header />
            <section className="auth_section">
                <div className="container">
                    <div className="auth_form">
                        <div className="title">
                            <h2 className="title-text title-text-lg">Sign up</h2>
                            <h4 className="page-subtitle">Create your account in seconds</h4>
                        </div>
                        <form className={(Object.keys(errors).length > 0) ? "needs-validation was-validated" : "needs-validation"} noValidate onSubmit={handleSubmit}>
                            <div className="col-md-12 mb-3 p-0">
                                <input type="text" className="form-control" placeholder="Full Name" name="fullname" onChange={handleChange} required/>
                                {errors.fullname && <div className="invalid-feedback d-block">{errors.fullname}</div>}
                            </div>
                            <div className="col-md-12 mb-3 p-0">
                                <input type="email" className="form-control" placeholder="Email id" name="email" onChange={handleChange} required/>
                                {errors.email && <div className="invalid-feedback d-block">{errors.email}</div>}
                            </div>
                            <div class="col-md-12 mb-3 p-0">
                                <input type="password" className="form-control" placeholder="Password" name="password" onChange={handleChange} required/>
                                {errors.password && <div className="invalid-feedback d-block">{errors.password}</div>}
                            </div>
                            <div class="col-auto mt-4 p-0">
                                <button type="submit" className="btn btn-md btn-block btn-primary boy-btn">Submit</button>
                            </div>
                        </form>
                    </div>
                    <p className="another_page_text">Already have an account?
                        <NavLink to="/login">Login</NavLink>
                    </p>
                </div>
            </section>
            <Footer />
        </>
    );
}
function validate(values){
    
    let errors = {};
    if (!values.fullname) {
        errors.fullname = 'Please enter fullname';
    }
    // if (!values.email) {
    //     errors.email = 'Please enter email';
    // }
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (!mailformat.test(values.email)) {
        errors.email = 'Please enter valid email.';
    }
    if (!values.password) {
      errors.password = 'Please enter password';
    }
   
    return errors;
}
export default Signup