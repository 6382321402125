import React, { useState, useEffect } from "react";
import HelmetExport, { Helmet } from "react-helmet";
import * as Comman from "../Utils/comman";
import Header from "../Components/Header";
import Footer from "../Components/Footer";

function Help(props) {
    // const [faq_cat_list, setFaqList] = useState({
    //     faqs: [],
    // });
    const [faqs, setFaqs] = useState([]);
    const [tabtitle, setTabtitle] = useState();
    
    useEffect(() => {
        getFaqs();
        window.scrollTo({ behavior: 'smooth', top: '0px' });
    }, []);
    const getFaqs = () => {
        Comman.callAPI("get", "/help-page-data").then((res) => {

            if (typeof res !== "undefined") {
                console.log(res);
                // setFaqList({
                //     faqs: res,
                // });
                setFaqs(res)
                //setTabtitle(res[0].help_type)
            }
        });

    }
    // const handleClick = (e) => {
    //     //console.log(faq_cat_list.faqs);
    //     //console.log(e);
    //     var result = faq_cat_list.faqs.filter(function (v, i) {
    //         //console.log(v['id']);
    //         //console.log(i);
    //         return v['id'] === e;
    //     });
    //     setFaqs(result[0].question_answer);
    //     setTabtitle(result[0].help_type)
    // };
    return (
        <>
            <Helmet>
                <title>{props.title}</title>
            </Helmet>
            <Header />
            <section className="page-header text-center d-flex justify-content-center">

                <div className="container-xl">
                    <h2 className="page-title">Frequently Asked Questions</h2>
                </div>
            </section>
            <section className="inner-page-wrapper help-wrapper">
                <div className="info-module">
                    <div className="container-xl">
                        <div
                            className="help-menu-mobile px-4 py-2 d-flex justify-content-between d-lg-none bg-primary-light rounded mb-3">
                            <h4 className="mb-0 font-primary text-dark">Help Menu</h4>
                            <button className="navbar-toggler d-lg-none border-0" type="button" data-toggle="collapse"
                                data-target="#helpTabs" aria-controls="helpTabs" aria-expanded="false"
                                aria-label="Toggle navigation"> <span className="navbar-toggler-icon"></span> </button>
                        </div>
                        <div className="row justify-content-between">
                            {/* <aside className="col-lg-auto sidebar-nav-warpper navbar-expand-lg">
                                <div className="collapse navbar-collapse" id="helpTabs">
                                    <div className="sidebar-nav d-flex flex-column nav nav-tabs">
                                        {faq_cat_list.faqs.map(faq => (
                                            <button href="#" className="sidebar-nav-link" onClick={() => handleClick(faq.id)}>
                                                {faq.help_type}
                                            </button>
                                        ))}
                                    </div>

                                </div>
                            </aside> */}
                            <div className="col-lg">
                                <div className="help-tab-content tab-content">
                                    <div id="ourBooks" className="tab-pane fade show active">
                                        <div className="title">
                                            <h2 className="title-text title-text-lg">{tabtitle}</h2>
                                        </div>
                                        <div className="help-content-module accordion" id="queAccordion">
                                            
                                                {faqs.map(faq => (
                                                    <>
                                                        <div className="card border-0 card-accordion">
                                                            <div className="card-header bg-light" id={"que_" + faq.id}>
                                                                <h4 className="d-flex justify-content-start align-items-center mb-0 collapsed"
                                                                    data-toggle="collapse" data-target={"#que" + faq.id} aria-expanded="true"
                                                                    aria-controls={"que" + faq.id}>
                                                                    <span className="collapse_icons"><i className="queIcon que-plus"></i>
                                                                    </span>
                                                                    <span className="queHead font-primary text-dark">{faq.help_question}</span>

                                                                </h4>
                                                            </div>
                                                            <div id={"que" + faq.id} className="collapse" aria-labelledby={"que" + faq.id}
                                                                data-parent="#queAccordion">
                                                                <div className="card-body">

                                                                    <p>{faq.help_answer}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                ))}
                                                
                                                
                                            
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />

        </>
    )
}
export default Help;