import "./App.css";
import React, { Fragment } from "react";
import Login from "./Pages/Login";
import Signup from "./Pages/Signup";
import ForgotPassword from "./Pages/ForgotPassword";
import ResetPassword from "./Pages/ResetPassword";
import BookDetail from "./Pages/BookDetail";
import EvolveDetail from "./Pages/evolve-detail";
import BookPreview from "./Pages/BookPreview";
import CheckOut from "./Pages/CheckOut";
import PaymentSuccess from "./Pages/PaymentSucess";
import OrderDetail from "./Pages/OrderDetail";
import { ProtectedRoute } from "./Components/Protected.route";
import { PrimaryMenu } from "./Utils/PrimaryMenu";
import { CurrencyIdProvider } from './Context/currency-context'

import {
  Routes,
  Route
} from "react-router-dom";
import { LoginSubmenu } from "./Utils/LoginSubmenu";
import { QuickLinksMenu } from "./Utils/QuickLinksMenu";
function App() {
  return (
    <div className="App">

      <Fragment>
        <CurrencyIdProvider>
          <Routes>
            {PrimaryMenu.map((item, index) => {
              if (!("submenu" in item)) {
                return (
                  <Route
                    key={index}
                    exact
                    path={item.path}
                    element={item.comp}
                  >
                    <Route exact path={item.path} element={item.comp} />
                  </Route>
                );
              } else {
                return (
                  <Route
                    path={item.path}
                    key={index}
                    element={item.comp}
                  >
                    <Route index={true} element={item.comp} key={index} />
                    {item.submenu.map((subitem, subindex) => {
                      return (
                        <Route
                          exact
                          path={subitem.path}
                          element={subitem.comp}
                          key={subindex}
                        />
                      );
                    })}
                  </Route>
                );
              }
            })}
            <Route path="/login" exact element={<Login title={`${process.env.REACT_APP_SITE_TITLE}: Login`} />} key="login" />
            <Route path="/signup" exact element={<Signup title={`${process.env.REACT_APP_SITE_TITLE}: Signup`} />} key="signup" />
            <Route path="/forgot-password" exact element={<ForgotPassword title={`${process.env.REACT_APP_SITE_TITLE}: Forgot Password`} />} key="forgot-password" />

            <Route path="/reset-password/:token" exact element={<ResetPassword title={`${process.env.REACT_APP_SITE_TITLE}: Reset Password`} />} key="reset-password" />
            <Route path="/book-detail/:bookid" exact element={<BookDetail title={`${process.env.REACT_APP_SITE_TITLE}: Book Detail`} />} key="book-detail" />
            <Route path="/preview-book" exact element={<BookPreview title={`${process.env.REACT_APP_SITE_TITLE}: Book Preview`} />} key="preview-book" />
            <Route path="/checkout" exact element={<CheckOut title={`${process.env.REACT_APP_SITE_TITLE}: Complete Your Order`} />} key="checkout" />
            <Route path="/payment-success" exact element={<PaymentSuccess title={`${process.env.REACT_APP_SITE_TITLE}: Payment Sucess`} />} key="payment" />
            <Route path="/evolve/:slug" exact element={<EvolveDetail title={`${process.env.REACT_APP_SITE_TITLE}: Evolve Detail`} />} key="evolve-detail" />


            {LoginSubmenu.map((item, index) => {
              return (
                <Route
                  key={index}
                  exact
                  path={item.path}
                  element={<ProtectedRoute />}
                >
                  <Route exact path={item.path} element={item.comp} />
                </Route>
              );
            })};
            <Route
              key='order-detail'
              exact
              path='/order-detail'
              element={<ProtectedRoute />}
            >
              <Route exact path='/order-detail' element={<OrderDetail title={`${process.env.REACT_APP_SITE_TITLE}: Order Detail`} />} />
            </Route>
            {QuickLinksMenu.map((item, index) => {
              // if(item.comp === ''){
              //   return (
              //     <HashLink key={index} to={item.path}>item.title</HashLink>
              //   );
              // }else{
              if (!("submenu" in item)) {
                return (
                  <Route
                    key={index}
                    exact
                    path={item.path}
                    element={item.comp}
                  >
                    <Route exact path={item.path} element={item.comp} />
                  </Route>
                );
              } else {
                return (
                  <Route
                    path={item.path}
                    key={index}
                    element={item.comp}
                  >
                    <Route index={true} element={item.comp} key={index} />
                    {item.submenu.map((subitem, subindex) => {
                      return (
                        <Route
                          exact
                          path={subitem.path}
                          element={subitem.comp}
                          key={subindex}
                        />
                      );
                    })}
                  </Route>
                );
              }
              //}
            })};
          </Routes>
        </CurrencyIdProvider>
      </Fragment>

    </div>
  );
}

export default App;
