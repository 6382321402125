import React, { useState, useEffect } from "react";
import * as Comman from "../../Utils/comman";
import kidswhite from '../../images/assets/kids-white.svg';
import pageswhite from '../../images/assets/pages-white.svg';
import arrow from '../../images/assets/arrow.svg';
import { useGlobalState } from '../../Context/currency-context';
import { Link } from "react-router-dom";

function LatestOffer(props) {
    const [book_list, setBookList] = useState({

        books: [],
    });
    // const [currId, setCurrId] = useState(1);
    const { currId } = useGlobalState();
    // let currency_id = localStorage.getItem("currency_id")

    useEffect(() => {
        console.log(currId)
        getBooks();

    }, [currId]);

    const getBooks = () => {
        Comman.callAPI("get", "/get-latest-offer").then((res) => {
            console.log(currId)
            if (typeof res !== "undefined") {
                let url = ""

                // console.log(typeof parseInt(currId))
                if (typeof currId !== "undefined") {
                    console.log(currId)
                    url = "/get-book-lowest-price?currency_id=" + currId
                }
                else {
                    url = "/get-book-lowest-price"
                }
                console.log(url)
                Comman.callAPI("get", url).then((lowest_price_res) => {
                    if (typeof res !== "undefined") {
                        console.log(lowest_price_res)

                        // setLowestPrices({
                        //     ...res
                        // });
                        // console.log(lowest_prices)


                        const updatedResults = res.map(item => {
                            // Modify the property you want to change (e.g., 'price')
                            // For example, changing the 'price' to a new value, let's say 999
                            console.log(item.slug)
                            console.log(lowest_price_res)
                            return {
                                ...item,
                                book_price: lowest_price_res[item.slug],
                                currency: lowest_price_res[item.slug + '-curr-symbol']
                                // currency: currency_id
                            };
                        });
                        console.log(updatedResults)
                        setBookList({
                            books: updatedResults,
                        });
                    }
                });
            }
        });
    }

    return (
        <>
            <section className="offers-wrapper pt-3 pt-md-5">
                <div className="container-xl">
                    <div className="title mb-2 mb-md-3 text-center">
                        <h2 className="title-text text-5xl mb-0">{props.title}</h2>
                        <a href="/browse-books" className="btn btn-md btn-primary mt-3">
                            Show more <img src={arrow} alt="arrow" />
                        </a>
                    </div>


                    <div id="books_all">
                        <div className="row">
                            {book_list.books.map(book => (
                                <div className="col-sm-6 col-md-6 col-lg-4 book-card-item">
                                    <div className="fancy-card book-card rounded-xl card h-100">
                                        <div className="card-header border-0 bg-transparent">
                                            <div className="card-title mb-0">
                                                <h4 className="text-dark text-xl mb-0">{book.book_name}</h4>
                                            </div>
                                        </div>
                                        <div className="card-body text-center">
                                            <div className="book-card-image">
                                                <img src={book.book_image} alt="book" class="img-fluid" />
                                            </div>
                                            <div className="book-summary bg-success text-white">
                                                <div className="row row-sm row-devider  align-items-center">
                                                    <div className="col-auto">
                                                        <div className="media align-items-center">
                                                            <div className="media-left mr-2">
                                                                <img src={kidswhite} alt="kids" width="28" />
                                                            </div>
                                                            <div className="media-body">
                                                                <h6 className="font-primary mb-0 text-base">{book.age_range} years</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-auto">
                                                        <div className="media align-items-center">
                                                            <div className="media-left mr-2">
                                                                <img src={pageswhite} alt="pages" width="28" />
                                                            </div>
                                                            <div className="media-body">
                                                                <h6 className="font-primary mb-0 text-base">{book.pages_number} pages</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="content" dangerouslySetInnerHTML={{ __html: book.book_description.substr(0, 100) + '...' }}>
                                            </div>
                                            <h4 className="font-primary text-dark text-2xl">
                                                {book.currency} {book.book_price}
                                            </h4>
                                        </div>
                                        <div className="card-footer border-0 bg-transparent">
                                            {/* <a href={"/book-detail/"+book.slug} className="btn btn-md btn-block btn-primary">
                                                Select Book <img src={arrow} alt="arrow" />
                                            </a> */}
                                            <Link to={`/book-detail/${book.slug}`} className="btn btn-md btn-block btn-primary">
                                                Select Book <img src={arrow} alt="arrow" />
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            ))}

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default LatestOffer;