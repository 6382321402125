
import React, { createContext, useContext, useState,useEffect  } from 'react';

// Create a context for the global state
const CurencyIdStateContext = createContext();

// Create a provider component
export const CurrencyIdProvider = ({ children }) => {
  const [currId, setCurrId] = useState(1);
  const [currDesc, setCurrDesc] = useState('INR');
  const [currData, setCurrData] = useState([]);

  const contextValue = {
    currId,
    setCurrId,
    currDesc,
    setCurrDesc,
    currData, // Include currData in the context value
    setCurrData,
  };
  // Load state from localStorage when the component mounts
  useEffect(() => {
    const savedState = localStorage.getItem('currencyState');
    if (savedState) {
      const { currId, currDesc, currData } = JSON.parse(savedState);
      setCurrId(currId);
      setCurrDesc(currDesc);
      setCurrData(currData);
    }
  }, []);

  // Save state to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem(
      'currencyState',
      JSON.stringify({ currId, currDesc, currData })
    );
  }, [currId, currDesc, currData]);
   
  return (
    <CurencyIdStateContext.Provider value={contextValue}>
      {children}
    </CurencyIdStateContext.Provider>
  );
};

// Custom hook to access the global state
export const useGlobalState = () => useContext(CurencyIdStateContext);
