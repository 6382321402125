import React from "react";

function HowItWorks() {
  return (
    <>
      <section className="process-wrapper py-3 py-md-4">
        <div className="container-xl py-md-2">
          <div className="title mb-2 mb-md-4 text-center">
            <h2 className="title-text text-5xl mb-1">We create customised story books <br/> with some simple inputs from you</h2>
            <h4 className="font-primary text-dark text-xl px-3 px-md-0 pb-2 pb-md-0">
              {/* <strong>On the book page follow these easy steps</strong> */}
            </h4>
          </div>
          <div className="row process-wrap justify-content-between mt-md-4 mb-md-5 pt-md-2 pb-md-5">
            <div className="col-md-3 process-item">
              <div className="process-card card text-center border-0 h-100">
                <div className="card-body px-4 py-3 d-flex flex-column justify-content-between">
                  <div className="process-number d-flex justify-content-center align-items-center">
                    1
                  </div>
                  <h5 className="process-title font-primary font-normal text-lg text-lg">
                      Choose your tale
                  </h5>
                </div>
                <div className="arrow-down">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    //xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="38px"
                    height="33px"
                  >
                    <g fill="#f9eaaf">
                        <path
                        fillRule="evenodd"
                        d="M2.099,-0.001 C3.854,-0.001 34.647,-0.001 35.858,-0.001 C37.427,-0.001 38.595,1.597 37.677,3.177 C36.942,4.444 22.101,29.747 20.831,31.907 C19.997,33.325 18.009,33.301 17.175,31.907 C16.244,30.352 1.327,4.952 0.291,3.113 C-0.469,1.766 0.370,-0.001 2.099,-0.001 Z"
                        />
                    </g>
                  </svg>
                </div>
              </div>
            </div>
            <div className="col-md-3 process-item">
              <div className="process-card card text-center border-0 h-100">
                <div className="card-body px-4 py-3 d-flex flex-column justify-content-between">
                  <div className="process-number d-flex justify-content-center align-items-center">
                    2
                  </div>
                  <h5 className="process-title font-primary font-normal text-lg">
                    Choose hero’s name and gender
                  </h5>
                </div>
                <div className="arrow-down">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="38px"
                    height="33px"
                  >
                    <g fill="#2d6fa3">
                    <path
                      fillRule="evenodd"
                      d="M2.099,-0.001 C3.854,-0.001 34.647,-0.001 35.858,-0.001 C37.427,-0.001 38.595,1.597 37.677,3.177 C36.942,4.444 22.101,29.747 20.831,31.907 C19.997,33.325 18.009,33.301 17.175,31.907 C16.244,30.352 1.327,4.952 0.291,3.113 C-0.469,1.766 0.370,-0.001 2.099,-0.001 Z"
                    />
                    </g>
                  </svg>
                </div>
              </div>
            </div>
            <div className="col-md-3 process-item">
              <div className="process-card card text-center border-0 h-100">
                <div className="card-body px-4 py-3 d-flex flex-column justify-content-between">
                  <div className="process-number d-flex justify-content-center align-items-center">
                    3
                  </div>
                  <h5 className="process-title font-primary font-normal text-lg text-lg">
                    Preview your book
                  </h5>
                </div>
                <div className="arrow-down">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="38px"
                    height="33px"
                  >
                    <g fill="#c4e7af">
                    <path
                      fillRule="evenodd"
                      d="M2.099,-0.001 C3.854,-0.001 34.647,-0.001 35.858,-0.001 C37.427,-0.001 38.595,1.597 37.677,3.177 C36.942,4.444 22.101,29.747 20.831,31.907 C19.997,33.325 18.009,33.301 17.175,31.907 C16.244,30.352 1.327,4.952 0.291,3.113 C-0.469,1.766 0.370,-0.001 2.099,-0.001 Z"
                    />
                    </g>
                  </svg>
                </div>
              </div>
            </div>
            <div className="col-md-3 process-item">
              <div className="process-card card text-center border-0 h-100">
                <div className="card-body px-4 py-3 d-flex flex-column justify-content-between">
                  <div className="process-number d-flex justify-content-center align-items-center">
                    4
                  </div>
                  <h5 className="process-title font-primary font-normal text-lg">
                    Place your order{" "}
                  </h5>
                </div>
                <div className="arrow-down">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="38px"
                    height="33px"
                  >
                    <g fill="#eabbdb">
                    <path
                      fillRule="evenodd"
                      d="M2.099,-0.001 C3.854,-0.001 34.647,-0.001 35.858,-0.001 C37.427,-0.001 38.595,1.597 37.677,3.177 C36.942,4.444 22.101,29.747 20.831,31.907 C19.997,33.325 18.009,33.301 17.175,31.907 C16.244,30.352 1.327,4.952 0.291,3.113 C-0.469,1.766 0.370,-0.001 2.099,-0.001 Z"
                    />
                    </g>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default HowItWorks;
