import { useToast, immediateToast } from 'izitoast-react'
import axios from "../Utils/axios";
import dev_axios from "../Utils/dev_axios";
import 'izitoast-react/dist/iziToast.css';
export const showMessage = (msg, type) => {

  switch (type) {
    case "success":
      //alert(msg);
      immediateToast('success', {
        title: "Success",
        message: msg,
        position: "topRight",
      })

      break;
    case "error":
      immediateToast('error', {
        title: "Error",
        message: msg,
        position: "topRight",
      })

      break;
    default:
      immediateToast('info', {
        title: "Info",
        message: msg,
        position: "topRight",
      })

  }
};
export const callAPI = (method, endpoint, data) => {
  let errors;
  axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth_access_token");
  if (method === "post") {

    return axios
      .post(endpoint, data)
      .then((res) => {

        if (res.data.status) {

          if (res.data.message !== "") {
            console.log(res.data.message)
            showMessage(`${res.data.message}`, "success");
          }
          return res.data.data;
        } else {
          errors = res.data.errors;
          Object.keys(errors).map(function (key, index) {

            showMessage(`${errors[key]}`, "error");
          });
        }
      })
      .catch((err) => {
        if (err.response.status === 400) {
          errors = err.response.data.errors;
          Object.keys(errors).map(function (key, index) {
            showMessage(`${errors[key]}`, "error");
          });
        } else if (err.response.status === 401) {
          showMessage("Access denied.", "error");
          window.location.href = "/";
        } else if (err.response.status === 404) {
          showMessage("API request not found", "error");
        } else {

          showMessage(err.message, "error");
          //window.location.href = '/';
        }
      });
  }
  if (method === "get") {

    return axios
      .get(endpoint)
      .then((res) => {
        if (res.data.status) {
          if (res.data.message !== "") {
            showMessage(`${res.data.message}`, "success");
          }
          return res.data.data;
        } else {
          errors = res.data.errors;
          Object.keys(errors).map(function (key, index) {
            showMessage(`${errors[key]}`, "error");
          });
        }
        //console.log(res);
      })
      .catch((err) => {
        if (err.response.status === 400) {
          errors = err.response.data.errors;
          Object.keys(errors).map(function (key, index) {
            showMessage(`${errors[key]}`, "error");
          });
        } else if (err.response.status === 401) {
          showMessage("Access denied.", "error");
          // window.location.href = "/";
        } else if (err.response.status === 404) {
          showMessage("API request not found", "error");
        } else {
          showMessage(err.message, "error");
          //window.location.href = "/";
        }
      });
  }
};

export const callDevAPI = (method, dev_endpoint, data) => {
  console.log(dev_endpoint)
  console.log(data)
  let errors;
  dev_axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth_access_token");
  if (method === "post") {

    return dev_axios
      .post(dev_endpoint, data)
      .then((res) => {

        if (res.data.status) {

          if (res.data.message !== "") {
            showMessage(`${res.data.message}`, "success");
          }
          return res.data.data;
        } else {
          errors = res.data.errors;
          Object.keys(errors).map(function (key, index) {

            showMessage(`${errors[key]}`, "error");
          });
        }
      })
      .catch((err) => {
        if (err.response.status === 400) {
          errors = err.response.data.errors;
          Object.keys(errors).map(function (key, index) {
            showMessage(`${errors[key]}`, "error");
          });
        } else if (err.response.status === 401) {
          showMessage("Access denied.", "error");
          window.location.href = "/";
        } else if (err.response.status === 404) {
          showMessage("API request not found", "error");
        } else {

          showMessage(err.message, "error");
          //window.location.href = '/';
        }
      });
  }
  if (method === "get") {

    return dev_axios
      .get(dev_endpoint)
      .then((res) => {
        if (res.data.status) {
          if (res.data.message !== "") {
            showMessage(`${res.data.message}`, "success");
          }
          return res.data.data;
        } else {
          errors = res.data.errors;
          Object.keys(errors).map(function (key, index) {
            showMessage(`${errors[key]}`, "error");
          });
        }
        //console.log(res);
      })
      .catch((err) => {
        if (err.response.status === 400) {
          errors = err.response.data.errors;
          Object.keys(errors).map(function (key, index) {
            showMessage(`${errors[key]}`, "error");
          });
        } else if (err.response.status === 401) {
          showMessage("Access denied.", "error");
          // window.location.href = "/";
        } else if (err.response.status === 404) {
          showMessage("API request not found", "error");
        } else {
          showMessage(err.message, "error");
          //window.location.href = "/";
        }
      });
  }
};

export const isAuthenticated = () => {
  let token = localStorage.getItem("auth_access_token");

  if (!token || token === "") {
    return false;
  } else {
    return true;
  }
};