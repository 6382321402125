import React from "react";
import whyusimg from '../../images/assets/why-us.png';
import arrow from '../../images/assets/arrow.svg';
function WhyUs() {
  return (
    <>
      <section className="why-us-wrapper pt-5 pt-md-3 pb-5 pb-md-0">
        <div className="container-xl">
          <div className="row justify-content-between">
            <div className="col-md-6 mb-4 mb-md-0">
              <div className="image-module mb-3">
                <img
                  src={whyusimg}
                  alt="family"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="title mb-2">
                <h2 className="title-text text-dark text-5xl">Our Purpose </h2>
              </div>
              {/* <h5 className="font-primary text-dark">
                <strong>The child is the hero!</strong>
              </h5>
              <p>
                Make any child the hero of their own storybook – full of
                courage, kindness and love. It’s simply magic for the child!
              </p> */}

              <p>
              Books are our best friends for life and our purpose is to introduce them to our children as
               early in life as possible. What could be better and more relatable  than a book that tells
                child's own tale in a fun and inspiring way alongside their loved ones. We want our children
                 to develop the art of reading,  learn, aspire and grow
              </p>
              <a href="/about-us" className="btn btn-md btn-primary mt-3">
                Show more <img src={arrow} alt="arrow" />
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default WhyUs;
