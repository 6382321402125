import React from "react";
import { PrimaryMenu } from "../Utils/PrimaryMenu";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import { Outlet } from "react-router-dom";
function PrimaryNavebar() {
  return (
    <>
      <div id="mainNavigation" className="collapse navbar-collapse col-md-6">
        <ul className="navbar-nav justify-content-end mx-auto" >
            {PrimaryMenu.map((item, index) => { 
                if(!('comp' in item)){
                    return (
                        <li key={index} className="nav-item">
                            <a href={item.externalurl} className={item.cName}>{item.title}</a>
                        </li>
                    );
                }else{
                    if(!('submenu' in item)){
                        return (
                            <li key={index} className="nav-item">
                                <NavLink
                                    key={index} to={item.path} className={({ isActive }) => (isActive ? item.cName+' active' : item.cName+' inactive')}>
                                        <span>{item.title}</span>
                                </NavLink>
                            </li>
                        );
                    }else{
                        
                        return (
                            <li className="nav-item dropdown" key={index}>
                                <Link
                                    key={index} to='#' className={item.cName}>
                                    <i className={item.icon}></i>&nbsp;<span>{item.title}</span>
                                </Link>
                                <ul className="dropdown-menu">
                                    {item.submenu.map((subitem, subindex) => {
                                        return (
                                            <li key={subindex}>
                                                <NavLink className={subitem.cName} to={subitem.path}>
                                                    {subitem.title}
                                                </NavLink>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </li>
                        );
                    }
                }
            })}
        </ul>
      </div>
    </>
  );
}

export default PrimaryNavebar;
