import React, {useEffect} from "react";
import { Helmet } from "react-helmet";
import Header from "../Components/Header";
import Banner from "../Components/Home/Banner";
import FormBooks from "../Components/Home/FormBooks";
import HowItWorks from "../Components/Home/HowItWorks";
import BrowseBooks from "../Components/Home/BrowseBooks";
import WhyUs from "../Components/Home/WhyUs";
import LatestOffer from "../Components/Home/LatestOffer";
import Subscribe from "../Components/Home/Subscribe";
import Footer from "../Components/Footer";
import '../stylecss/style.css';

function Home(props) {
  useEffect(() => {
    window.scrollTo({ behavior: 'smooth', top: '0px' });
  });
  return (
    
    <>
      <Helmet>
        <title>{props.title}</title>
      </Helmet>
      <Header />
      <Banner />
      <FormBooks />
      <LatestOffer title="Latest Arrivals"/>
      <HowItWorks />
      <BrowseBooks />
      <WhyUs />
      <Subscribe />
      <Footer />
    </>
  );
}

export default Home;
