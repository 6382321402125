import React from 'react';
import logoimg from '../images/logo/i-tell-my-tale-logo.png';
import { Link } from 'react-router-dom';
function Logo() {
    return (
        <div className='col-md-1 p-0'>
            <Link className="navbar-brand" to="/"> 
                <img src={logoimg} className="img-fluid" alt="logo"/> 
            </Link>
        </div>
    );
}

export default Logo;