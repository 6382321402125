import React, { useState, useEffect } from "react";
import HelmetExport, { Helmet } from "react-helmet";
import * as Comman from "../Utils/comman";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import queryString from "query-string";
import PageHeader from "../Components/BrowseBook/PageHeader";
import LatestOffer from "../Components/Home/LatestOffer";
function OrderDetail(props) {
    const queryParams = queryString.parse(window.location.search);
    const [isLoad, setIsLoad] = useState(false);
    const [orderItemDetail, setOrderItemDetail] = useState([]);
    const [orderId, setOrderId] = useState();
    const [transectionId, setTransectionId] = useState();
    const [shippingAddress, setShippingAddress] = useState();
    const [billingAddress, setBillingAddress] = useState();
    const [totalAmount, setTotalAmount] = useState();
    const [currSymbol, setcurrSymbol] = useState();
    //const [couponName, setCouponName] = useState();
    const [discountAmount, setDiscountAmount] = useState();
    const [PDFLink, setPDFLink] = useState();
    useEffect(() => {
        //console.log(queryParams.id)
        if (!isLoad) {
            //setBookImages(initialState);
            getOrderDetails();
        }
    });

    const InProgress = (e) => {
        Comman.showMessage(`PDF In progress`, "info");
    }

    const getOrderDetails = () => {

        Comman.callAPI("get", "/order-detail/" + queryParams.id).then((res) => {
            if (typeof res !== "undefined") {
                console.log(res.order_details[0].book)
                setOrderItemDetail(res.order_details);
                setOrderId(res.razorpay_order_id);
                setDiscountAmount(res.discount_amount);
                setTransectionId(res.razorpay_payment_id);
                setShippingAddress(res.full_name + "<br/>" + res.email + ",<br/>" + res.street_address_1 + ", " + (res.street_address_2 != null ? res.street_address_2 + ',<br/>' : '') + " " + res.city + ", " + res.state + ",<br/>" + res.country + ", " + res.post_code);
                setBillingAddress((res.billing_street_address_1 != null ? res.billing_street_address_1 + ', ' : '') + "" + (res.billing_street_address_2 != null ? res.billing_street_address_2 + ',<br/>' : '') + " " + (res.billing_city != null ? res.billing_city + ',<br/>' : '') + "" + (res.billing_state != null ? res.billing_state + ',<br/>' : '') + "" + (res.billing_country != null ? res.billing_country + ', ' : '') + "" + (res.billing_post_code != null ? res.billing_post_code + '<br/>' : ''));
                setTotalAmount(res.total_amount);
                setcurrSymbol(res.curr_symbol);
                setIsLoad(true);
                
                if (res != '') {
                    setPDFLink()
                }

                //setIsBookLoad(true);
            }
        });
    }
    return (
        <>
            <Helmet>
                <title>{props.title}</title>
            </Helmet>
            <Header />

            <section className="ordertabel">
                <div className="container table-responsive">
                    <h2 className="title-text title-text-lg">Order Detail</h2>
                    <div className="orderbox">

                        <div className="orderid">
                            <h3>Order {orderId}</h3>
                            <p className="price" style={{fontSize:"22px", fontWeight:"bold"}}>{currSymbol} {totalAmount}</p>
                        </div>

                        <hr />
                        <p>Transaction Id: {transectionId}</p>
                        <p>Discount Amount: {currSymbol} {discountAmount}</p>
                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <p>Shipping address :</p>
                                <div dangerouslySetInnerHTML={{ __html: shippingAddress }}></div>
                            </div>
                            <div className="col-md-6">
                                <p>Billing address :</p>
                                <div dangerouslySetInnerHTML={{ __html: billingAddress }}></div>
                            </div>
                        </div>

                    </div>

                    <h2 className="title-text title-text-lg mgtop50">Order timeline & Details</h2>
                    <div className="orderbox ordertimeline">
                        {orderItemDetail.map(book1 => (
                            <div className="row mb-3">
                                <div className="col-md-3">
                                    <div className="book-image">
                                        <a href={"/book-detail/" + book1.book_id}>
                                            <img src={book1.book.image_server_path + "/" + book1.book.book_image} alt="book" className="img-fluid" />
                                        </a>
                                    </div>
                                </div>
                                <div className="col-md-9">
                                    <h3>{book1.book.book_name}</h3>
                                    <p>{currSymbol} {book1.book_price_on_order} </p>
                                    <div className="userbookdetail" dangerouslySetInnerHTML={{ __html: book1.book_value_html }} ></div>
                                    
                                    {
                                        book1.book.book_pdf_status === 1 
                                        ? <a className="btn btn-sm btn-primary" target="_blank" href={book1.book.book_pdf_link}>Download PDF</a> 
                                        : book1.book.book_pdf_status === 2 
                                            ? <a className="btn btn-sm btn-primary" href="javascript:void(0)" onClick={InProgress}>PDF In progress</a> 
                                            : <div></div>
                                        }
                                </div>
                            </div>
                        ))}


                    </div>
                </div>
            </section>

            <Footer />
        </>
    );
}
export default OrderDetail;