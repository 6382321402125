import React from "react";
import Home from "../Pages/Home";
import BrowseBook from "../Pages/BrowseBook";
import AboutUs from "../Pages/AboutUs";
import Evolve from "../Pages/evolve";
import Help from "../Pages/Help";

export const PrimaryMenu = [
    {
      title: "Home",
      icon: "",
      path: "/",
      cName: "nav-link",
      comp: <Home title={process.env.REACT_APP_SITE_TITLE}/>,
    },

    {
        title: "Evolve Framework",
        icon: "",
        path: "/evolve",
        cName: "nav-link",
        comp: <Evolve title={`${process.env.REACT_APP_SITE_TITLE}: Evolve`}/>,
    },
    {
        title: "Browse Books",
        icon: "",
        path: "/browse-books",
        cName: "nav-link",
        comp: <BrowseBook title={`${process.env.REACT_APP_SITE_TITLE}: Browse Books`}/>,
    },
    {
        title: "About Us",
        icon: "",
        path: "/about-us",
        cName: "nav-link",
        comp: <AboutUs title={`${process.env.REACT_APP_SITE_TITLE}: About Me`}/>,
    },
   
    {
        title: "FAQ",
        icon: "",
        path: "/faq",
        cName: "nav-link",
        comp: <Help title={`${process.env.REACT_APP_SITE_TITLE}: FAQ`}/>,
    },
    
    
];