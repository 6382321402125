import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import * as Comman from "../Utils/comman";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import { NavLink } from "react-router-dom";
import '../stylecss/auth.css';
function ForgotPassword(props) {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [values, setValues] = useState({
        email: "",
    });
    const [errors, setErrors] = useState({});
    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        setErrors(validate(values));
        setIsSubmitting(true);
    };
    useEffect(() => {
        
        if (Object.keys(errors).length === 0 && isSubmitting) {
           
            const data = {
                email: values.email,
            }
            Comman.callAPI('post', '/forgot-password', data).then(
                res => {
                    setIsSubmitting(false);
                }
            )
            setIsSubmitting(false);
            //console.log("submit");
        }
    }, [isSubmitting]);
    return (
        <>
            <Helmet>
                <title>{props.title}</title>
            </Helmet>
            <Header />
            <section className="auth_section">
                <div className="container">
                    <div className="auth_form">
                        <div className="title">
                            <h2 className="title-text title-text-lg">Forgot Password</h2>
                            <h4 className="page-subtitle">This will help you reset your password</h4>
                        </div>
                        <form className={(Object.keys(errors).length > 0) ? "needs-validation was-validated" : "needs-validation"} noValidate onSubmit={handleSubmit}>
                            <div className="col-md-12 mb-3 p-0">
                                <input type="email" className="form-control" placeholder="Email id" name="email" onChange={handleChange} required />
                            </div>
                            <div className="col-auto mt-4 p-0">
                                <button type="submit" className="btn btn-md btn-block btn-primary boy-btn">Recover Password</button>
                            </div>
                            <a href="javascript:void(0)" className="forgot_text"></a>
                        </form>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}
function validate(values){
    let errors = {};
   
    if (!values.email) {
        errors.email = 'Please enter email';
    }
   
    return errors;
}
export default ForgotPassword