import React, { useState, useEffect } from "react";
import * as Comman from "../../Utils/comman";
import Pagination from '../Pagination';

import kidswhite from '../../images/assets/kids-white.svg';
import pageswhite from '../../images/assets/pages-white.svg';
import arrow from '../../images/assets/arrow.svg';
import queryString from "query-string";
import { useGlobalState } from '../../Context/currency-context';
import { Link } from "react-router-dom";
function BookList() {
    const queryParams = queryString.parse(window.location.search);
    console.log(queryParams)

    const [book_list, setBookList] = useState({
        books: [],
    });
    const [filter, setFilter] = useState('');
    const [isLoad, setIsLoad] = useState(false);
    const [CategoryData, setCategoryData] = useState([]);
    const [CategoryId, setCategoryId] = useState('');
    const [AgeId, setAgeId] = useState('');

    const { currId } = useGlobalState();
    useEffect(() => {
        if (!isLoad) {
            getCategoryData();
            
            

        }
    }, [isLoad, currId]);


    // useEffect(() => {
    //     if (!isLoad) {
            
    //     }
    //     // window.scrollTo({ behavior: 'smooth', top: '0px' });
    // });

    const getCategoryData = () => {
        Comman.callAPI("get", "/category", '').then((res) => {
            if (typeof res !== "undefined") {
                console.log(res);
                setCategoryData(res);
                // setBookImage(res.books_gallery_image);
                setIsLoad(true);
            }
        });
        getBooks();
    }

    const handleCategoryChange = (e) => {
        console.log(e.target)
        const { name, value } = e.target;
        setCategoryId(value)
        getBooks(filter, '', '5', value);
        console.log(value)

    }

    const handleAgeChange = (e) => {
        console.log(e.target)
        const { name, value } = e.target;

        console.log(value)
        setFilter(value);
        getBooks(value, '', '5', CategoryId);

    }


    const handleFilter = (value) => {
        setFilter(value);
        getBooks(value);

    }

    const getBooks = (ageRange = '', pageNumber = '', pageSize = '5', category = '') => {
        const data = {
            page: pageNumber,
            per_page: pageSize,
            age_range: ageRange,
            your_name: queryParams.your_name,
            your_gender: queryParams.your_gender,
            category_id: category
        }
        console.log(data)

        Comman.callAPI("post", "/all-books", data).then((res) => {
            console.log(currId)

            Comman.callAPI("get", "/get-book-lowest-price?currency_id=" + currId).then((lowest_price_res) => {
                if (typeof res !== "undefined") {
                    console.log(lowest_price_res)


                    let url = ""

                    if (typeof currId !== "undefined") {
                        console.log(currId)
                        url = "/get-book-lowest-price?currency_id=" + currId
                    }
                    else {
                        url = "/get-book-lowest-price"
                    }

                    const updatedResults = res.result.map(item => {
                        // Modify the property you want to change (e.g., 'price')
                        // For example, changing the 'price' to a new value, let's say 999
                        console.log(item.slug)
                        console.log(lowest_price_res)
                        return {
                            ...item,
                            book_price: lowest_price_res[item.slug],
                            currency: lowest_price_res[item.slug + '-curr-symbol']
                        };
                    });

                    if (typeof res !== "undefined") {
                        console.log(res)
                        console.log(updatedResults)

                        setBookList({
                            books: updatedResults,
                            perPage: res.limit,
                            totalPages: res.pages,
                            totalCount: res.total,
                            currentPage: res.current_page
                        });

                    }
                }
            });
        });

    }
    return (
        <>
            <section className="inner-page-wrapper">
                <div className="info-module">
                    <div className="container-xl">
                        <div className="browse-books">
                            <ul className="d-flex justify-content-center justify-content-md-end" id="">
                                <li>
                                    <label className="book-label">
                                        Select Age Range
                                    </label>
                                    <select className="select" id="age" onChange={handleAgeChange} name="price_type">
                                        {/* {options.map} */}
                                        <option value="">All</option>
                                        <option value="1">2 - 5 Years</option>
                                        <option value="2">6 - 10 Years</option>

                                    </select>
                                </li>
                                <li className="ml-4">
                                    <label className="book-label" >
                                        Select Evolve Skill
                                    </label>
                                    <select className="select ml-4" id="category" onChange={handleCategoryChange} name="price_type">
                                        {/* {options.map} */}
                                        <option value="0">All</option>
                                        {CategoryData.map(category => (

                                            <option value={category.cat_id}>{category.name}</option>

                                        ))}
                                    </select>
                                </li>
                                <li>

                                </li>
                                {/* <li>
                                    <span className={(filter === '3') ? 'badge badge-secondary active' : 'badge badge-secondary'} onClick={() => handleFilter('3')}>5-10 year</span>
                                </li> */}
                            </ul>
                            <div id="books_all">
                                <div className="row">
                                    {book_list.books.length > 0 ? (
                                        book_list.books.map(book => (
                                            <div className="col-sm-6 col-md-6 col-lg-4 book-card-item" key={book.slug}>
                                                <div className="fancy-card book-card rounded-xl card h-100">
                                                    <div className="card-header border-0 bg-transparent">
                                                        <div className="card-title mb-0">
                                                            <h4 className="text-dark text-xl mb-0">{book.book_name}</h4>
                                                        </div>
                                                    </div>
                                                    <div className="card-body text-center">
                                                        <div className="book-card-image">
                                                            <img src={book.book_image} alt="book" className="img-fluid" />
                                                        </div>

                                                        <div className="book-summary bg-success text-white">
                                                            <div className="row row-sm row-divider align-items-center">
                                                                <div className="col-auto">
                                                                    <div className="media align-items-center">
                                                                        <div className="media-left mr-2">
                                                                            <img src={kidswhite} alt="kids" width="28" />
                                                                        </div>
                                                                        <div className="media-body">
                                                                            <h6 className="font-primary mb-0 text-base">{book.age_range} years</h6>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-auto">
                                                                    <div className="media align-items-center">
                                                                        <div className="media-left mr-2">
                                                                            <img src={pageswhite} alt="pages" width="28" />
                                                                        </div>
                                                                        <div className="media-body">
                                                                            <h6 className="font-primary mb-0 text-base">{book.pages_number} pages</h6>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={'book-summary ' + book.class_name}>
                                                            <div className="row row-sm row-divider align-items-center justify-content-center">
                                                                <div className="col-auto">
                                                                    <div className="media align-items-center justify-content-center">
                                                                        <div className="media-body text-center">
                                                                            <h6 className="font-primary mb-0 text-center" data-toggle="tooltip" data-placement="top" title={book.category_title}>{book.category_name}</h6>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="content" dangerouslySetInnerHTML={{ __html: book.book_description.substr(0, 100) + '...' }}>
                                                        </div>
                                                        <h4 className="font-primary text-dark text-2xl">
                                                            {book.currency} {book.book_price}
                                                        </h4>
                                                    </div>
                                                    <div className="card-footer border-0 bg-transparent">
                                                        {Object.keys(queryParams).length > 0 ? (
                                                            <Link
                                                                to={{
                                                                    pathname: `/book-detail/${book.slug}`,
                                                                    search: `?isReEdit=yes&your_name=${queryParams.your_name}&your_gender=${queryParams.your_gender}`,
                                                                }}
                                                                className="btn btn-md btn-block btn-primary"
                                                            >
                                                                Select Book <img src={arrow} alt="arrow" />
                                                            </Link>
                                                        ) : (
                                                            <Link to={`/book-detail/${book.slug}`} className="btn btn-md btn-block btn-primary">
                                                                Select Book <img src={arrow} alt="arrow" />
                                                            </Link>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <div className="col-12 text-center">
                                            <h4>No books available</h4>
                                        </div>
                                    )}

                                </div>
                                {book_list.books.length > 0 ? (
                                    <Pagination perPage={book_list.perPage} totalPages={book_list.totalPages} totalCount={book_list.totalCount} currentPage={book_list.currentPage} paginate={getBooks} />
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
}
export default BookList;