import axios from "axios";
let token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
const instance = axios.create({
  baseURL: "https://indodevserver.com/itellmytale/public_html/admin/api",
  headers: {
    "Content-Type": "application/json",
    // "xsrfHeaderName":"X-XSRF-TOKEN",
    // "withCredentials": true,
    // "xsrfCookieName": "csrftoken" 
    //Authorization: "Bearer " + localStorage.getItem("auth_access_token"),
    //'Authorization': 'Bearer 123'
  },
});
//baseURL: "https://api.prastaratech.com/api",
export default instance;