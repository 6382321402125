import React, {useState, useEffect} from "react";
import * as Comman from "../Utils/comman";
import {Helmet} from "react-helmet";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import PageHeader from "../Components/CheckOut/PageHeader";
import LatestOffer from "../Components/Home/LatestOffer";
import arrow from "../images/assets/arrow.svg";
import deletee from "../images/assets/delete.svg";
import editee from "../images/assets/edit.svg";
import {useNavigate} from "react-router-dom";
import {parse} from "query-string";
import { useGlobalState } from '../Context/currency-context';

function Checkout(props) {
  const navigate = useNavigate();
  const [cartData, setCartData] = useState([]);
  const [subTotal, setSubTotal] = useState("0.00");
  const [shipping, setShipping] = useState("0.00");
  const [discountAmount, setDiscountAmount] = useState("0.00");
  const [total, setTotal] = useState("0.00");
  const [isLoad, setIsLoad] = useState(false);
  const [userId, setUserId] = useState("");
  const [isLogin, setIsLogin] = useState("no");
  const [couponCode, setCouponCode] = useState("");
  const [couponData, setCouponData] = useState();
  const [checkoutData, setCheckoutData] = useState({
    is_billing_address_same: "0",
    billing_country: "",
    billing_post_code: "",
    billing_street_address_1: "",
    billing_street_address_2: "",
    billing_city: "",
    billing_state: "",
    billing_mobile_number: "",
    total: "",
  });
  const [errors, setErrors] = useState({});
  const [countryList, setcountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [isBilling, setBilling] = useState(true);
  const [quantity, setQuantity] = useState([]);
  const { currId, setCurrId } = useGlobalState();
  const [ currDesc, setCurrDesc ] = useState();
  // const { currDesc, setCurrDesc } = useGlobalState();
  const { currData, setCurrData } = useGlobalState();
  useEffect(() => {
    
    if (!isLoad) {
      
      getCountryList();
    }
    getCartData();
    localStorage.removeItem('selected_book_id')
    localStorage.removeItem('selected_gender')
    localStorage.removeItem('selected_name')
    localStorage.removeItem('selected_price_type')
    localStorage.removeItem('is_first_login')
    
  }, [currId]);

  const getCartData = () =>{
    let userData = JSON.parse(localStorage.getItem("userData"));
    let user_id = "";
      if (userData) {
        user_id = userData.id;
        setUserId(userData.id);
        setIsLogin("yes");
      } else {
        user_id = localStorage.getItem("itell_guest_user_id");
        setUserId(localStorage.getItem("itell_guest_user_id"));
      }
      Comman.callAPI("get", "/get-cart-product/" + user_id+'?currency_id='+currId).then((res) => {
        if (typeof res !== "undefined") {
          console.log(res)
          for (let i = 0; i < res.cart.length; i++) {
            setQuantity((current) => [...current, res.cart[i].book_quantity]);
          }
          setCartData(res.cart);
          setSubTotal(res.subTotal);
          setShipping(res.shippingTotal);
          setTotal(res.allTotal);
          console.log(currDesc)
          setCurrDesc(res.curr_desc)
          // setIsLoad(true);
        }
      });
  }

  
  const getCountryList = () => {
    Comman.callAPI("get", "/states ").then((res) => {
      setStateList(res);
    });
    Comman.callAPI("get", "/countries ").then((res) => {
      setcountryList(res);
    });
  };
  const handleIncrement = (e) => {
    let cart_id = e.target.id;
    let index = e.target.getAttribute("data-index");
    const newQunt = [...quantity];
    newQunt[index] = quantity[index] + 1;
    //if(newQunt[index] > 0){
    setQuantity(newQunt);
    let unique_id = localStorage.getItem("itell_guest_user_id");
    const qunt_data = {
      cart_id: cart_id,
      user_id: isLogin == "yes" ? userId : unique_id,
      book_quantity: newQunt[index],
      coupon_id: couponData ? couponData.id : 0,
      currency_id: currId
    };
    Comman.callAPI("post", "/add-quantity", qunt_data).then((res) => {
      if (typeof res !== "undefined") {
        //console.log(res)
        if (res.cart.length === 0) {
          navigate("/");
        } else {
          setCartData(res.cart);
          setSubTotal(res.subTotal);
          setShipping(res.shippingTotal);
          setTotal(res.allTotal);
          //window.location.reload(false);
        }
      }
    });
    //}
  };
  const handleDecrement = (e) => {
    let cart_id = e.target.id;
    let index = e.target.getAttribute("data-index");
    const newQunt = [...quantity];
    newQunt[index] = quantity[index] - 1;
    if (newQunt[index] > 0) {
      setQuantity(newQunt);
      let unique_id = localStorage.getItem("itell_guest_user_id");
      const qunt_data = {
        cart_id: cart_id,
        user_id: isLogin == "yes" ? userId : unique_id,
        book_quantity: newQunt[index],
        coupon_id: couponData ? couponData.id : 0,
        currency_id: currId
      };
      Comman.callAPI("post", "/add-quantity", qunt_data).then((res) => {
        if (typeof res !== "undefined") {
          //console.log(res)
          if (res.cart.length === 0) {
            navigate("/");
          } else {
            setCartData(res.cart);
            setSubTotal(res.subTotal);
            setShipping(res.shippingTotal);
            setTotal(res.allTotal);
            //window.location.reload(false);
          }
        }
      });
    }
  };
  const handleDelete = (e) => {
    e.preventDefault();
    let cart_id = e.target.id;
    let unique_id = localStorage.getItem("itell_guest_user_id");
    const delete_data = {
      cart_id: cart_id,
      user_id: isLogin == "yes" ? userId : unique_id,
      currency_id: currId
    };

    Comman.callAPI("post", "/delete-cart-product", delete_data).then((res) => {
      if (typeof res !== "undefined") {
        localStorage.setItem("cartCount", res.cart.length);

        if (res.cart.length === 0) {
          navigate("/");
        } else {
          setCartData(res.cart);
          setSubTotal(res.subTotal);
          setShipping(res.shippingTotal);
          setTotal(res.allTotal);
          // window.location.reload(false);
        }
      }
    });
  };
  const handleEdit = (e) => {
    e.preventDefault();
    let cart_id = e.target.id;
    var find_result = cartData.find((item) => item.id === parseInt(cart_id));
    console.log(find_result)
    localStorage.setItem("edit_book", JSON.stringify(find_result));
    let sel_book_id = find_result.book_id;
    navigate("/book-detail/" + find_result.book.slug + "?isEdit=yes");
  };
  const addCouponCode = (e) => {
    setCouponCode(e.target.value);
  };
  const handleApplyCoupon = (e) => {
    e.preventDefault();
    let unique_id = localStorage.getItem("itell_guest_user_id");
    const coupon_data = {
      coupon_name: couponCode,
      user_id: isLogin == "yes" ? userId : unique_id,
      currency_id: currId
    };

    Comman.callAPI("post", "/apply-coupon", coupon_data).then((res) => {
      if (typeof res !== "undefined") {
        //console.log(res);
        setCartData(res.cart);
        setSubTotal(res.subTotal);
        setShipping(res.shippingTotal);
        setDiscountAmount(res.discountAmount);
        setCouponData(res.couponData);
        setTotal(res.allTotal);
      }
    });
  };
  const handleClearAll = (e) => {
    e.preventDefault();
    let unique_id = localStorage.getItem("itell_guest_user_id");
    const delete_data = {
      user_id: isLogin == "yes" ? userId : unique_id,
      currency_id: currId
    };

    Comman.callAPI("post", "/clear-cart", delete_data).then((res) => {
      if (typeof res !== "undefined") {
        // setCartData(res.cart);
        // setSubTotal(res.subTotal);
        // setShipping(res.shippingTotal);
        // setTotal(res.allTotal);
        //setIsLoad(true);
        localStorage.setItem("cartCount", 0);
        navigate("/");
      }
    });
  };
  // const handleGiftWrap = (e) => {
  //     e.preventDefault();
  //     let cart_id = e.target.getAttribute('dataid');
  //     console.log(cart_id);
  //     const gift_data = {
  //         'cart_id': cart_id,
  //     }

  //     Comman.callAPI("post", "/gift-wrap",gift_data).then((res) => {
  //         if (typeof res !== "undefined") {
  //             localStorage.setItem('cartCount', res.cart.length);

  //             if(res.cart.length === 0){
  //                 navigate("/");
  //             }else{
  //                 setCartData(res.cart);
  //                 setSubTotal(res.subTotal);
  //                 setShipping(res.shippingTotal);
  //                 setTotal(res.allTotal);
  //                window.location.reload(false);
  //             }
  //         }
  //     });
  // }
  const handleFormChange = (e) => {
    if (e.target.id === "billingAddress") {
      setCheckoutData({
        ...checkoutData,
        ["is_billing_address_same"]: e.target.checked ? "1" : "0",
        ['currency_id']: currId
      });
      setBilling(e.target.checked ? false : true);
    } else {
      const {name, value} = e.target;
      setCheckoutData({
        ...checkoutData,
        [name]: value,
      });
    }
  };
  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }
  async function displayRazorpay() {
    if (cartData.length == 0) {
      alert("Please add books.");
      return false;
    }
    const button = document.getElementById('payButton');
    let errors = validate(checkoutData, isBilling);
    setErrors(errors);
    if (Object.keys(errors).length === 0) {
      button.classList.add('fade-out');
      let userData = JSON.parse(localStorage.getItem("userData"));
      let is_guest_user = "Yes";
      let unique_id = localStorage.getItem("itell_guest_user_id");
      checkoutData.user_id = isLogin == "yes" ? userId : unique_id;
      if (userData) {
        is_guest_user = "no";
      }
      checkoutData.is_guest_user = is_guest_user;
      checkoutData.total = total;
      checkoutData.token_data = localStorage.getItem("auth_access_token");
      checkoutData.coupon_id = couponData ? couponData.id : 0;
      setCheckoutData({...checkoutData, cancel_url: "/"});
      // const res = await loadScript(
      //     "https://checkout.razorpay.com/v1/checkout.js"
      // );

      // if (!res) {
      //     alert("Razorpay SDK failed to load. Are you online?");
      //     return;
      // }

      //const result = await axios.post("http://localhost:5000/payment/orders");
      // const checkout_data = {
      //     'book_id': '',
      // }

      // console.log(checkoutData);
      // console.log(cartData);
      // console.log(isBilling);

      console.log(checkoutData)

      const result = await Comman.callAPI(
        "post",
        "/checkout",
        checkoutData
      ).then((res) => {
        if (typeof res !== "undefined") {
          button.classList.remove('fade-out');
          localStorage.setItem("razorpayOrderId", res.order_id);
          window.location.href = res.link;
          localStorage.setItem('cartCount',0)
        } else {
          return false;
        }
      });

      return false;
      const result1 = await Comman.callAPI(
        "post",
        "/get-url",
        checkoutData
      ).then((res) => {
        if (typeof res !== "undefined") {
          localStorage.setItem("razorpayOrderId", res.order_id);
          window.location.href = res.link;

          // res=Object.keys(res).map((key) => [key, res[key]])
          // var iihhh=window.open(res.link, "_blank", "menubar=no,top=0, left=0,fullscreen=yes,location=no,resizable=no, scrollbars=yes, titlebar=yes, width="+window.innerWidth+", height="+window.innerHeight+",");
          // checkoutData.id = res.id;
          // setInterval(async function checkstats()
          // {
          //    return  await Comman.callAPI("post", "/get-data-api", checkoutData).then((res) => {

          //     console.log(res)
          //     if(res.status==1)
          //     {
          //         iihhh.close();
          //         navigate("/payment-success");
          //     }
          //     else if(res.status==2)
          //     {

          //     }
          //     else{
          //         window.reload();
          //     }
          //    });
          // },1000);
          // return res;
        } else {
          return false;
        }
      });

      if (result) {
        const options = {
          key: "rzp_test_nFWoGUmds5HiOY", // Enter the Key ID generated from the Dashboard
          amount: 500,
          currency: result.razorPaymentData.currency,
          name: result.full_name,
          description: "Test Transaction",
          //image: { logo },
          order_id: result.razorPaymentData.id,
          handler: async function (response) {
            const paymentdata = {
              orderCreationId: result.razorPaymentData.id,
              razorpayPaymentId: response.razorpay_payment_id,
              razorpayOrderId: response.razorpay_order_id,
              razorpaySignature: response.razorpay_signature,
              order_id: result.id,
            };
            const result_payment = await Comman.callAPI(
              "post",
              "/payment-response",
              paymentdata
            ).then((res) => {
              if (typeof res !== "undefined") {
                return res;
              } else {
                return false;
              }
            });

            if (result_payment.payment_status === 1) {
              localStorage.setItem(
                "razorpayOrderId",
                result_payment.razorpay_order_id
              );
              localStorage.setItem(
                "razorpayPaymentId",
                result_payment.razorpay_payment_id
              );
              localStorage.setItem("cartCount", 0);
              navigate("/payment-success");
            } else {
              console.log("Payment fail");
            }
          },
          prefill: {
            name: checkoutData.full_name,
            email: checkoutData.email,
            contact: checkoutData.mobile_number,
          },
          notes: {
            address:
              checkoutData.street_address_1 +
              ", " +
              checkoutData.street_address_2 +
              ", " +
              checkoutData.city +
              " " +
              checkoutData.state,
          },
          // theme: {
          //     color: "#61dafb",
          // },
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
      }
    }
  }
  // async function displayRazorpay() {
  //     let errors = validate(checkoutData, isBilling);
  //     setErrors(errors);
  //     if (Object.keys(errors).length === 0) {
  //         let userData = JSON.parse(localStorage.getItem("userData"));
  //         let is_guest_user = 'Yes';
  //         let unique_id = localStorage.getItem("itell_guest_user_id");
  //         checkoutData.user_id = (isLogin == 'yes' ? userId : unique_id);
  //         if (userData) {
  //             is_guest_user = 'no'
  //         }
  //         checkoutData.is_guest_user = is_guest_user;
  //         checkoutData.coupon_id = (couponData ? couponData.id : 0);
  //         const res = await loadScript(
  //             "https://checkout.razorpay.com/v1/checkout.js"
  //         );

  //         if (!res) {
  //             alert("Razorpay SDK failed to load. Are you online?");
  //             return;
  //         }

  //         //const result = await axios.post("http://localhost:5000/payment/orders");
  //         // const checkout_data = {
  //         //     'book_id': '',
  //         // }
  //         const result = await Comman.callAPI("post", "/checkout", checkoutData).then((res) => {
  //             if (typeof res !== "undefined") {
  //                 return res;
  //             } else {
  //                 return false;
  //             }
  //         });

  //         if (result) {
  //             const options = {
  //                 key: "rzp_test_nFWoGUmds5HiOY", // Enter the Key ID generated from the Dashboard
  //                 amount: 500,
  //                 currency: result.razorPaymentData.currency,
  //                 name: result.full_name,
  //                 description: "Test Transaction",
  //                 //image: { logo },
  //                 order_id: result.razorPaymentData.id,
  //                 handler: async function (response) {

  //                     const paymentdata = {
  //                         orderCreationId: result.razorPaymentData.id,
  //                         razorpayPaymentId: response.razorpay_payment_id,
  //                         razorpayOrderId: response.razorpay_order_id,
  //                         razorpaySignature: response.razorpay_signature,
  //                         order_id: result.id
  //                     };
  //                     const result_payment = await Comman.callAPI("post", "/payment-response", paymentdata).then((res) => {
  //                         if (typeof res !== "undefined") {
  //                             return res;
  //                         } else {
  //                             return false;
  //                         }
  //                     });

  //                     if (result_payment.payment_status === 1) {
  //                         localStorage.setItem('razorpayOrderId', result_payment.razorpay_order_id)
  //                         localStorage.setItem('razorpayPaymentId', result_payment.razorpay_payment_id)
  //                         localStorage.setItem('cartCount', 0)
  //                         navigate("/payment-success");
  //                     } else {
  //                         console.log("Payment fail");
  //                     }

  //                 },
  //                 prefill: {
  //                     name: checkoutData.full_name,
  //                     email: checkoutData.email,
  //                     contact: checkoutData.mobile_number,
  //                 },
  //                 notes: {
  //                     address: checkoutData.street_address_1 + ", " + checkoutData.street_address_2 + ", " + checkoutData.city + " " + checkoutData.state,
  //                 },
  //                 // theme: {
  //                 //     color: "#61dafb",
  //                 // },
  //             };

  //             const paymentObject = new window.Razorpay(options);
  //             paymentObject.open();

  //         }
  //     }
  // }
  return (
    <>
      <Helmet>
        <title>{props.title}</title>
      </Helmet>
      <Header />
      <PageHeader />
      <section className="inner-page-wrapper checkout-module">
        <div className="info-module">
          <div className="container-xl">
            <div className="row no-gutters justify-content-between">
              <div className="col-md checkout-form">
                <a
                  href="/browse-books"
                  className="btn btn-pay-now btn-primary shopping"
                >
                  <img src={arrow} alt="arrow" /> Continue Shopping
                </a>
                <h4 className="font-primary text-dark text-2xl">
                  <strong>Shipping Address</strong>
                </h4>
                <p>Enter your shipping details</p>
                <div className="form-group">
                  <label for="">Full Name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="full_name"
                    onChange={handleFormChange}
                    required
                  />
                  {errors.full_name && (
                    <div className="invalid-feedback d-block">
                      {errors.full_name}
                    </div>
                  )}
                </div>
                <div className="form-group">
                  <label for="">Email (Order detail will be send here)</label>
                  <input
                    type="text"
                    className="form-control"
                    name="email"
                    onChange={handleFormChange}
                    required
                  />
                  {errors.email && (
                    <div className="invalid-feedback d-block">
                      {errors.email}
                    </div>
                  )}
                </div>

                <div className="row row-cols-1 row-cols-lg-2  justify-content-between">
                  <div className="col col-custom-w form-group">
                    <label for="">Country</label>
                    <select
                      className="custom-select"
                      name="country"
                      onChange={handleFormChange}
                      required
                    >
                      <option selected>Country</option>
                      {countryList.map((c1) => (
                        //console.log(typeof c1)
                        <option key={c1.name} value={c1.name}>
                          {c1.name}
                        </option>
                      ))}
                    </select>
                    {errors.country && (
                      <div className="invalid-feedback d-block">
                        {errors.country}
                      </div>
                    )}
                  </div>
                  <div className="col col-custom-w form-group">
                    <label for="">Postcode</label>
                    <input
                      type="text"
                      className="form-control"
                      name="post_code"
                      onChange={handleFormChange}
                      required
                    />
                    {errors.post_code && (
                      <div className="invalid-feedback d-block">
                        {errors.post_code}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <label for="">Street Adress 1</label>
                  <input
                    type="text"
                    className="form-control"
                    name="street_address_1"
                    onChange={handleFormChange}
                    required
                  />
                  {errors.street_address_1 && (
                    <div className="invalid-feedback d-block">
                      {errors.street_address_1}
                    </div>
                  )}
                </div>
                <div className="form-group">
                  <label for="">Street Adress 2</label>
                  <input
                    type="text"
                    className="form-control"
                    name="street_address_2"
                    onChange={handleFormChange}
                  />
                </div>
                <div className="row row-cols-1 row-cols-md-2  justify-content-between">
                  <div className="col col-custom-w form-group">
                    <label for="">City</label>
                    <input
                      type="text"
                      className="form-control"
                      name="city"
                      onChange={handleFormChange}
                      required
                    />
                    {errors.city && (
                      <div className="invalid-feedback d-block">
                        {errors.city}
                      </div>
                    )}
                  </div>
                  <div className="col col-custom-w form-group">
                    <label for="">State</label>
                    <select
                      className="custom-select"
                      name="state"
                      onChange={handleFormChange}
                      required
                    >
                      <option selected>State</option>
                      {stateList.map((c1) => (
                        //console.log(typeof c1)
                        <option key={c1.name} value={c1.name}>
                          {c1.name}
                        </option>
                      ))}
                    </select>
                    {errors.state && (
                      <div className="invalid-feedback d-block">
                        {errors.state}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <label for="">Mobile</label>
                  <input
                    type="text"
                    className="form-control"
                    name="mobile_number"
                    onChange={handleFormChange}
                    required
                  />
                  {errors.mobile_number && (
                    <div className="invalid-feedback d-block">
                      {errors.mobile_number}
                    </div>
                  )}
                </div>
                <div className="form-group custom-control custom-checkbox fancy-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="billingAddress"
                    key="billingAddress"
                    name="is_billing_address_same"
                    onChange={handleFormChange}
                  />
                  <label className="custom-control-label" for="billingAddress">
                    Billing adress is same as shipping address
                  </label>
                </div>
                <div className={isBilling ? "block" : "d-none"}>
                  <h4 className="font-primary text-dark text-2xl">
                    <strong>Billing Address</strong>
                  </h4>
                  <p>Enter your billing details</p>

                  <div className="row row-cols-1 row-cols-lg-2  justify-content-between">
                    <div className="col col-custom-w form-group">
                      <label for="">Country</label>
                      <select
                        className="custom-select"
                        name="billing_country"
                        onChange={handleFormChange}
                        required
                      >
                        <option selected>Country</option>
                        {/* {Object.keys(countryList).map(c1 => {
                                                //console.log(countryList[c1].name);
                                                <option key={c1} value={countryList[c1].name}>{countryList[c1].name}</option>
                                              
                                            })} */}
                        {countryList.map((c1) => (
                          //console.log(typeof c1)
                          <option key={c1.name} value={c1.name}>
                            {c1.name}
                          </option>
                        ))}
                      </select>
                      {errors.country && (
                        <div className="invalid-feedback d-block">
                          {errors.billing_country}
                        </div>
                      )}
                    </div>
                    <div className="col col-custom-w form-group">
                      <label for="">Postcode</label>
                      <input
                        type="text"
                        className="form-control"
                        name="billing_post_code"
                        onChange={handleFormChange}
                        required
                      />
                      {errors.post_code && (
                        <div className="invalid-feedback d-block">
                          {errors.billing_post_code}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <label for="">Street Adress 1</label>
                    <input
                      type="text"
                      className="form-control"
                      name="billing_street_address_1"
                      onChange={handleFormChange}
                      required
                    />
                    {errors.street_address_1 && (
                      <div className="invalid-feedback d-block">
                        {errors.billing_street_address_1}
                      </div>
                    )}
                  </div>
                  <div className="form-group">
                    <label for="">Street Adress 2</label>
                    <input
                      type="text"
                      className="form-control"
                      name="billing_street_address_2"
                      onChange={handleFormChange}
                    />
                  </div>
                  <div className="row row-cols-1 row-cols-md-2  justify-content-between">
                    <div className="col col-custom-w form-group">
                      <label for="">City</label>
                      <input
                        type="text"
                        className="form-control"
                        name="billing_city"
                        onChange={handleFormChange}
                        required
                      />
                      {errors.city && (
                        <div className="invalid-feedback d-block">
                          {errors.billing_city}
                        </div>
                      )}
                    </div>
                    <div className="col col-custom-w form-group">
                      <label for="">State</label>
                      <select
                        className="custom-select"
                        name="billing_state"
                        onChange={handleFormChange}
                        required
                      >
                        <option selected>State</option>
                        {stateList.map((c1) => (
                          //console.log(typeof c1)
                          <option key={c1.name} value={c1.name}>
                            {c1.name}
                          </option>
                        ))}
                      </select>
                      {errors.state && (
                        <div className="invalid-feedback d-block">
                          {errors.billing_state}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <label for="">Mobile</label>
                    <input
                      type="text"
                      className="form-control"
                      name="billing_mobile_number"
                      onChange={handleFormChange}
                      required
                    />
                    {errors.billing_mobile_number && (
                      <div className="invalid-feedback d-block">
                        {errors.billing_mobile_number}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md checkout-summary">
                <h4 className="font-primary text-dark text-2xl d-flex align-items-center justify-content-between">
                  <strong>Order Summary</strong>
                  {Object.keys(cartData).length != 0 ? (
                    <a
                      href="javascript:void(0)"
                      className="clearall"
                      onClick={handleClearAll}
                    >
                      <img src={deletee} alt="edit" /> Clear All
                    </a>
                  ) : null}
                </h4>
                <p>Review your order</p>
                <div className="table-cart table-mobile-responsive mb-2">
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">Item</th>
                        <th scope="col">Quantity</th>
                        <th scope="col">Actions</th>
                        <th scope="col" align="right">
                          Price({currDesc})
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.keys(cartData).length > 0 ? (
                        Object.keys(cartData).map((cart) => (
                          <tr>
                            <td title="Item">
                              <span className="item-name">
                                {cartData[cart].book.book_name}
                              </span>
                              <div
                                className="userbookdetail"
                                dangerouslySetInnerHTML={{
                                  __html: cartData[cart].book_value_html,
                                }}
                              ></div>
                            </td>
                            {/* <td title="Gift Wrap">
                                                        <div className="custom-control custom-switch">
                                                            <input type="checkbox" className="custom-control-input" id={"giftWrap"+cartData[cart].id} key={cartData[cart].id} dataid={cartData[cart].id} onChange={handleGiftWrap} defaultChecked={cartData[cart].gift_wrap === 1 ? true : false}/>
                                                            <label className="custom-control-label" for={"giftWrap"+cartData[cart].id} key={cartData[cart].id}></label>
                                                        </div>
                                                    </td> */}
                            <td title="Quantity">
                              <div class="row">
                                <div className="col-lg">
                                  <div className="input-group">
                                    <span className="input-group-prepend">
                                      <button
                                        type="button"
                                        className="quantity-left-minus btn btn-danger btn-number btn-xs"
                                        id={cartData[cart].id}
                                        data-index={cart}
                                        onClick={handleDecrement}
                                      >
                                        -
                                      </button>
                                    </span>
                                    <input
                                      type="text"
                                      name="quantity"
                                      className="form-control input-number form-control-sm"
                                      value={quantity[cart]}
                                      min="1"
                                      max="100"
                                    />
                                    <span className="input-group-append">
                                      <button
                                        type="button"
                                        className="quantity-right-plus btn btn-success btn-number btn-xs"
                                        id={cartData[cart].id}
                                        data-index={cart}
                                        onClick={handleIncrement}
                                      >
                                        +
                                      </button>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td title="Actions">
                              
                              <a
                                href="#"
                                className="cart-delete"
                                onClick={handleDelete}
                              >
                                <img
                                  src={deletee}
                                  width="18"
                                  alt="delete"
                                  id={cartData[cart].id}
                                />
                              </a>
                            </td>
                            <td title="Price(INR)" align="right">
                              {cartData[cart].book.book_price}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={3} align="center">
                            No cart item found
                          </td>
                        </tr>
                      )}
                    </tbody>
                    {Object.keys(cartData).length != 0 ? (
                      <tfoot>
                        <tr>
                          <th colspan="3" className="text-dark">
                            Subtotal:
                          </th>
                          <th colspan="1" className="text-dark">
                            {subTotal}
                          </th>
                        </tr>
                        <tr>
                          <th colspan="3" className="text-dark">
                            <form>
                              <div className="form-row coupon-input align-items-center">
                                <div className="col-lg-auto">Coupon</div>
                                <div className="col-md-auto">
                                  <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    onChange={addCouponCode}
                                  />
                                </div>
                                <div className="col-md-auto">
                                  <button
                                    type="submit"
                                    className="btn btn-primary"
                                    onClick={handleApplyCoupon}
                                  >
                                    Apply
                                  </button>
                                </div>
                              </div>
                            </form>
                          </th>
                          <th colspan="1" className="text-dark">
                            {discountAmount}
                          </th>
                        </tr>
                        <tr>
                          <th colspan="3" className="text-dark">
                            Shipping:
                          </th>
                          <th colspan="1" className="text-dark">
                            {shipping === 0 ? "Free" : shipping}
                          </th>
                        </tr>
                        <tr>
                          <th colspan="3" className="text-dark">
                            Total({currDesc}):
                          </th>
                          <th colspan="1" className="text-dark">
                            {total}
                          </th>
                        </tr>
                      </tfoot>
                    ) : null}
                  </table>
                </div>
                <div className="payment-information">
                  <h4 className="font-primary text-dark font-bold text-2xl payment-title">
                    Payment Information
                  </h4>
                  <p className="payment-subtitle">100% secure payment</p>
                  <h6 className="font-primary text-dark text-base payment-card">
                    {" "}
                    Credit Card, Debit Card, % Internet Banking- PayU
                  </h6>
                </div>
                <div className="payment-information">
                  <h4 className="font-primary text-dark font-bold text-2xl payment-title">
                  Payment Notice:
                  </h4>
                  <p className="payment-subtitle font-bold" style={{color:"#2d6fa3"}}>Prices are displayed in various currencies for your convenience, but all payments will be processed in INR. We apologize for any inconvenience this may cause.</p>
                  {/* <h6 className="font-primary text-dark text-base payment-card">
                    {" "}
                    Prices are displayed in various currencies for your convenience, but all payments will be processed in INR. We apologize for any inconvenience this may cause.
                  </h6> */}
                </div>
                <div className="payment-button-box">
                  <button
                  id="payButton"
                    className="btn btn-pay-now btn-primary w-100"
                    onClick={displayRazorpay}
                  >
                    Pay Securely Now
                    <img src={arrow} alt="arrow" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <br />
      <LatestOffer title="You May Also Like" />
      <Footer />
    </>
  );
}
function validate(values, isBilling) {
  let errors = {};
  if (!values.full_name) {
    errors.full_name = "Please enter full form";
  }
  var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  if (!mailformat.test(values.email)) {
    errors.email = "Please enter valid email.";
  }
  if (!values.country) {
    errors.country = "Please enter country";
  }
  if (!values.post_code) {
    errors.post_code = "Please enter post code";
  }
  if (!values.street_address_1) {
    errors.street_address_1 = "Please enter address 1";
  }
  if (!values.city) {
    errors.city = "Please enter city";
  }
  if (!values.state) {
    errors.state = "Please enter state";
  }
  // if (!values.mobile_number) {
  //     errors.mobile_number = 'Please enter mobile number';
  // }
  var re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
  if (!re.test(values.mobile_number)) {
    errors.mobile_number = "Please enter only number with 10 digits format.";
  }

  if (isBilling) {
    // if (!values.billing_full_name) {
    //     errors.billing_full_name = 'Please enter full form';
    // }
    // var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    // if (!mailformat.test(values.billing_email)) {
    //     errors.billing_email = 'Please enter valid email.';
    // }
    if (!values.billing_country) {
      errors.billing_country = "Please enter country";
    }
    if (!values.billing_post_code) {
      errors.billing_post_code = "Please enter post code";
    }
    if (!values.billing_street_address_1) {
      errors.billing_street_address_1 = "Please enter address 1";
    }
    if (!values.billing_city) {
      errors.billing_city = "Please enter city";
    }
    if (!values.billing_state) {
      errors.billing_state = "Please enter state";
    }
    // if (!values.mobile_number) {
    //     errors.mobile_number = 'Please enter mobile number';
    // }
    var re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
    if (!re.test(values.billing_mobile_number)) {
      errors.billing_mobile_number =
        "Please enter only number with 10 digits format.";
    }
  }
  return errors;
}
export default Checkout;
