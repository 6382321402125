import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Subscribe from "../Components/Home/Subscribe";
import * as Comman from "../Utils/comman";
function PrivacyPolicy(props) {
    const [pageData, setPageData] = useState([]);
    const [isLoad, setIsLoad] = useState(false);
    useEffect(() => {
        if (!isLoad) {
            getPageData();
            
        }
        window.scrollTo({ behavior: 'smooth', top: '0px' });
    });
    const getPageData = () => {
        let page_name = ''
        if (props.title ==='Terms and conditions'){
            page_name = 'term_condition'
        }
        else{
            page_name = 'shipping_and_delivery_policy'
        }
        console.log(page_name)
        const data = {
            page_name: page_name
          }
        Comman.callAPI("post", "/page-content", data).then((res) => {
            if (typeof res !== "undefined") {
                //console.log(res);
                setPageData(res);
                // setBookImage(res.books_gallery_image);
                setIsLoad(true);
            }
        });
    }
    return (
        <>
            <Helmet>
                <title>{props.title}</title>
            </Helmet>
            <Header />
            <section className="page-header text-center d-flex justify-content-center">
                <div className="container-xl">
                    <h2 className="page-title">{props.title}</h2>
                </div>
            </section>
            <section className="privacy-section pt-3 pt-md-5">
                <div className="container-xl">
                    <div className="content" dangerouslySetInnerHTML={{__html: pageData.page_content}}>
                    </div>
                </div>
            </section>
            <Subscribe />
            <Footer />
        </>
    );
}

export default PrivacyPolicy;