import React from "react";
import { Helmet } from "react-helmet";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import PageHeader from "../Components/BrowseBook/PageHeader";
import BookList from "../Components/BrowseBook/BookList";
import '../stylecss/booklist.css';

function BrowseBook(props) {
    return (
        <>
          <Helmet>
            <title>{props.title}</title>
          </Helmet>
          <Header />
          <PageHeader />
          <BookList />
          <Footer />
        </>
      );
}
export default BrowseBook;