import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import * as Comman from "../Utils/comman";
import Header from "../Components/Header";
import Footer from "../Components/Footer";

import { Link } from "react-router-dom";
// import 
function Evolve(props) {
    const [pageData, setPageData] = useState([]);
    const [isLoad, setIsLoad] = useState(false);
    useEffect(() => {
        if (!isLoad) {
            getPageData();
        }
        window.scrollTo({ behavior: 'smooth', top: '0px' });
    });
    const getPageData = () => {
        Comman.callAPI("get", "/category", '').then((res) => {
            if (typeof res !== "undefined") {
                //console.log(res);
                setPageData(res);
                // setBookImage(res.books_gallery_image);
                setIsLoad(true);
            }
        });
    }
    return (
        <>
            <Helmet>
                <title>{props.title}</title>
            </Helmet>
            <Header />

            {/* <PageHeader subTitle={pageData.page_tag_line} /> */}
            {/* <section className="inner-page-wrapper aboutus-wrapper">
                <div className="info-module">
                    <div className="container-xl">
                        <div className="row justify-content-between align-items-center">
                            <div className="col-md-auto mb-4 mb-lg-0 order-2 order-md-1 mx-md-auto">
                                <div className="fancy-card">
                                    <img src={pageData.section_first_image} alt="family" className="img-fluid" />
                                </div>
                            </div>
                            <div className="col-md explore-content order-1 order-md-2 aboutus-content">
                                <div className="title mb-2">
                                    <h2 className="title-text title-text-lg">{pageData.section_first_headline}</h2>
                                </div>
                                <div dangerouslySetInnerHTML={{ __html: pageData.section_first_content }}></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="promo-section text-center d-flex align-items-center">
                    <div className="container-xl">
                        <div className="row justify-content-center">
                            <div className="col-auto">
                                <h2 className="promo-text text-white text-5xl">{pageData.section_second_headline}</h2>
                                <h5 className="promo-sub-text  text-white font-primary text-xl">
                                    <div dangerouslySetInnerHTML={{ __html: pageData.section_second_content }}></div>
                                </h5>
                            </div>
                        </div>


                    </div>
                </div>
                <div className="info-module explore-module">
                    <div className="container-xl">
                        <div className="row justify-content-between align-items-center">
                            <div className="col-md-auto mb-4 mb-md-0 order-1 order-md-2 mx-md-auto">
                                <div className="fancy-card">
                                    <img src={pageData.section_third_image} alt="family" className="img-fluid" />
                                </div>
                            </div>
                            <div className="col-md explore-content order-2 order-md-1">
                                <div dangerouslySetInnerHTML={{ __html: pageData.section_third_content }}></div>

                            </div>
                        </div>
                    </div>
                </div> */}

                <div className="evolve-sec">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3">
                            </div>
                            <div className="col-md-6">
                                <div className="evolve-bg">

                                    {pageData.map(evolve => (
                                        <div className={`image-${evolve.id}`}>
                                        {/* <a href="#">
                                            <img src={icon1} className="icon" alt="icon" />
                                            </a> */}
                                            <Link to={`/evolve/${evolve.slug}`} ><img src={evolve.image} className="icon" alt="icon" />
                                                        </Link>
                                        <div className={`text-${evolve.id}`}>
                                            {/* <h2>E -<span> Emotional & Cognitive Development </span></h2> */}
                                            <h2>{evolve.name}</h2>
                                            {/* <p>{evolve.frame_work}  
                                                <Link to={`/evolve/${evolve.slug}`} >  Read More</Link>
                                            </p> */}
                                            <div dangerouslySetInnerHTML={{ __html: evolve.frame_work }}></div>
                                            <p><Link to={`/evolve/${evolve.slug}`} >  Read More</Link></p>
                                        </div>
                                    </div>
                                    ))
                                    }
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            {/* </section> */}
            <Footer />
        </>
    )
}
export default Evolve