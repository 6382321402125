import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import * as Comman from "../Utils/comman";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import PageHeader from "../Components/AboutUs/PageHeader";

import bookreading from '../images/assets/book-reading.png';
import explore from '../images/assets/explore.png';
function MyAccount(props) {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [values, setValues] = useState({
        name: "",
        email: "",
        telephone: "",
    });
    const [errors, setErrors] = useState({});
    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues({
          ...values,
          [name]: value,
        });
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        let error = validate(values); 
        setErrors(error);
        if(Object.keys(error).length === 0){
            setIsSubmitting(true);
        }else{
            setIsSubmitting(false);
        }
    };
    useEffect(() => {
        getData();

    }, []);
    useEffect(() => {
        if (Object.keys(errors).length === 0 && isSubmitting) {
            const data = {
                name: values.name,
                email: values.email,
                phone_number: values.telephone
              
            }
            Comman.callAPI("post", "/profile-update", data).then((res) => {
                setIsSubmitting(false);
                if (typeof res !== "undefined") {
                    console.log(res);
                } 
            });
            
             
          }
    }, [isSubmitting]);
    const getData = () => {
        Comman.callAPI("get", "/user").then((res) => {
            if (typeof res !== "undefined") {
                setValues({
                    name: res.name,
                    email: res.email,
                    telephone: res.phone_number
                });
            }
        });

    }
    return(
        <>
            <Helmet>
                <title>{props.title}</title>
            </Helmet>
            <Header />
            <section className="auth_section">
                <div className="container">
                    <div className="auth_form">
                        <div className="title">
                            <h2 className="title-text title-text-lg">Your Details</h2>
                            <h4 className="page-subtitle">Edit your details here</h4>
                        </div>
                        <form className={(Object.keys(errors).length > 0) ? "needs-validation was-validated" : "needs-validation"} noValidate onSubmit={handleSubmit}>
                            <div className="col-md-12 mb-3 p-0">
                                <label>Full Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Full Name"
                                    name="name"
                                    onChange={handleChange}
                                    value={values.name}
                                    required
                                />
                                {errors.name && <div className="invalid-feedback d-block">{errors.name}</div>}
                            </div>
                            <div className="col-md-12 mb-3 p-0">
                                <label>Email id</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    placeholder="Email"
                                    name="email"
                                    onChange={handleChange}
                                    value={values.email}
                                    required
                                />
                                {errors.email && <div className="invalid-feedback d-block">{errors.email}</div>}
                            </div>
                            <div className="col-md-12 mb-3 p-0">
                                <label>Telephone</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Telephone"
                                    name="telephone"
                                    onChange={handleChange}
                                    value={values.telephone}
                                    required
                                />
                                {errors.telephone && <div className="invalid-feedback d-block">{errors.telephone}</div>}
                            </div>
                            <div className="col-auto mt-4 p-0">
                                <button
                                    type="submit"
                                    className="btn btn-md btn-block btn-primary boy-btn">
                                    Update
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}
function validate(values){
    let errors = {};
    if (!values.name) {
        errors.email = 'Please enter name';
    }
    if (!values.email) {
      errors.email = 'Please enter email';
    }
    var re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
    if (!re.test(values.telephone)) {
        errors.telephone = 'Please enter only number with 10 digits format.';
    }
    return errors;
}
export default MyAccount;